import { DomainProperty, DomainPropertySuggestionResponse } from '@home-in/models'
import { AutocompleteRenderOptionState } from '@mui/material'
import React from 'react'

export default function CustomAutocompleteOption({
  props,
  option,
  state,
  optionCount,
}: {
  props: React.HTMLAttributes<HTMLLIElement> & {
    key: any
  }
  option: DomainPropertySuggestionResponse[0]
  state: AutocompleteRenderOptionState
  optionCount: number
}) {
  // Removes the bottom border for the last option
  const borderStyle = optionCount === state.index + 1 ? 'none' : '1px solid var(--grey-400, #BDBDBD)'
  const { id, address } = option as DomainProperty

  return (
    <li
      {...props}
      key={id || address || ''}
      style={{ paddingTop: '8px', paddingBottom: '8px', borderBottom: borderStyle }}
    >
      {address}
    </li>
  )
}
