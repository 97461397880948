import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'
import { MergedHbjAndChecklist, MergedSellPropertyAndChecklist } from './properties.helpers'

export const getUnarchivedBuyProperties = ({
  buyProperties,
  removedBuyChecklists,
}: {
  buyProperties: MergedHbjAndChecklist[]
  removedBuyChecklists: WaratahPropertyWithChecklist[]
}) => {
  return buyProperties.filter((property) => {
    const {
      propertyChecklistInfo: { propertyArchived, propertyId, hbjId },
    } = property as MergedHbjAndChecklist

    const buyPropertyIsArchived = !!removedBuyChecklists?.find(
      (removedChecklist) => removedChecklist?.propertyId === propertyId && removedChecklist?.hbjId === hbjId
    )

    return !(propertyArchived || buyPropertyIsArchived)
  })
}

export const getUnarchivedSellProperties = ({
  sellProperties,
  removedSellProperties,
}: {
  sellProperties: MergedSellPropertyAndChecklist[]
  removedSellProperties: string[]
}) => {
  return sellProperties.filter((property) => {
    const {
      propertyInfo: { propertyArchived, propertyId },
    } = property as MergedSellPropertyAndChecklist

    const sellPropertyIsArchived = !!removedSellProperties?.find((removedProperty) => removedProperty === propertyId)

    return !(propertyArchived || sellPropertyIsArchived)
  })
}
