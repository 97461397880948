import { GetMetadataResponse, USER_METADATA_ENUM } from '@home-in/models'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useRouter } from 'next/router'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { CBAYelloOfferTile } from '@elements/banners/cba-yello-offer'
import { EmojiFeedbackBanner } from '@elements/banners/emoji-feedback-banner'
import { SellSideNewFeatureBanner } from '@elements/banners/sell-side-new-feature'
import { SharingBanner } from '@elements/banners/sharing-banner'
import PropertySearchBar from '@elements/property-search/property-search-bar-container'
import { LoadingWrapper } from '@elements/status-handlers/loading-wrapper'
import { NotificationGroup, NotificationItem } from '@features/home/components/notification-group'
import { PreApprovalCard } from '@features/home/components/preapproval-card'
import { useAcknowledgeSellSideGetStartedCard } from '@hooks/useAcknowledgeSellSideLookingToSellCard'
import useCBAPreApprovalHBJ from '@hooks/useCBAPreApprovalHBJ'
import { useProperties } from '@hooks/useProperties'
import { useSellUiChanges } from '@hooks/useSellUIChanges'
import { useShowCbaYelloTile } from '@hooks/useShowCbaYelloTile'
import { useGetUserMetadataQuery } from '@redux/apis/profile'
import { useAppSelector } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { AnalyticsCategories, ButtonClickEventNames } from '@utils/helpers/analytics.enum'
import { AddPropertyCard } from '../components/add-property-card'
import { BuyAndSellPropertyCards } from '../components/buy-and-sell-property-cards'
import { PropertyCardUIContainer } from '../components/property-card-ui'
import { getLenderTileDetails } from '../utils/get-lender-tile-details'
import { getCombinedPropertiesSortedByPropertyAddedDateTime } from '../utils/get-properties.helper'

const showSellSideGetStartedCard = ({
  userMetadata,
  userMetadataIsLoading,
  userMetadataIsError,
  sellProperties,
}: {
  userMetadata: GetMetadataResponse | undefined
  userMetadataIsLoading: boolean
  userMetadataIsError: boolean
  sellProperties: Record<string, any>[]
}) => {
  if (!sellProperties || userMetadataIsLoading || userMetadataIsError || !userMetadata) {
    return false // don't show card when data still loading
  }
  if (sellProperties.length > 0) {
    return false // don't show if property added already
  }
  return (
    userMetadata?.find((metadata) => metadata.metadata_type === USER_METADATA_ENUM.acknowledgeSellSideGetStartedCard)
      ?.value !== true
  )
}

export const Activity = () => {
  const cbaPreApprovalHBJ = useCBAPreApprovalHBJ()
  const { sellSideAddProperty, sellSideShowActivitiesCard, enhanceAddProperty } = useFlags()
  const uiChangesFromSell = useSellUiChanges()
  const router = useRouter()

  const {
    dataWithoutArchivedProperties: properties,
    unarchivedBuyProperties,
    unarchivedSellProperties,
    loading: isCombinedPropertiesLoading,
    isError: isCombinedPropertiesError,
  } = useProperties()
  const isAnyProperties = !!properties?.length && properties?.length > 0
  const isAnyBuySideProperties = !!unarchivedBuyProperties?.length && unarchivedBuyProperties?.length > 0

  const home = useAppSelector((state) => state.home)
  const showCbaYelloTile = useShowCbaYelloTile()

  const lenderTile = getLenderTileDetails(home)

  const items: NotificationItem[] = [
    {
      component: <PreApprovalCard {...cbaPreApprovalHBJ?.loan!} />,
      active: !!cbaPreApprovalHBJ?.loan?.loanAmount,
    },
    {
      illustration: lenderTile.illustration,
      title: lenderTile.title,
      message: lenderTile.message,
      callback: lenderTile.callback,
      active: lenderTile.active && isAnyBuySideProperties,
    },
  ]

  // BEL-2151 dismissable card
  const {
    data: userMetadata,
    isLoading: userMetadataIsLoading,
    isError: userMetadataIsError,
  } = useGetUserMetadataQuery()
  const { acknowledgeSellSideGetStartedCard } = useAcknowledgeSellSideGetStartedCard()
  const sellSideNewFeatureBannerDismissed = useRef(false) // workaround saving metadata latency
  const isSellSideGetStartedCardVisible =
    sellSideShowActivitiesCard &&
    !sellSideNewFeatureBannerDismissed.current &&
    showSellSideGetStartedCard({
      userMetadata,
      userMetadataIsLoading,
      userMetadataIsError,
      sellProperties: unarchivedSellProperties,
    })
  const dispatch = useDispatch()

  const YourOffers = () => (
    <div className="mt-8">
      <EnhancedHomeInnerSection>
        <EnhancedHomeSubtitle>Your offers</EnhancedHomeSubtitle>
        <div className="lg:grid lg:grid-cols-2 lg:gap-4">
          <CBAYelloOfferTile />
        </div>
      </EnhancedHomeInnerSection>
    </div>
  )

  const LegacyHomePageLayout = () => {
    return (
      <div>
        {isSellSideGetStartedCardVisible && (
          <SellSideNewFeatureBanner
            callbackDismiss={() => {
              acknowledgeSellSideGetStartedCard()
              sellSideNewFeatureBannerDismissed.current = true
              dispatch(
                trackButtonClick({
                  action: ButtonClickEventNames.SellSideAddPropertyCardDismissed,
                  category: AnalyticsCategories.HomeScreen,
                })
              )
            }}
            callbackAction={() => {
              router.push('/property/add')
              dispatch(
                trackButtonClick({
                  action: ButtonClickEventNames.SellSideAddPropertyCardActioned,
                  category: AnalyticsCategories.HomeScreen,
                })
              )
            }}
          />
        )}

        <EmojiFeedbackBanner />

        <NotificationGroup items={items.filter((item) => item.active)} />

        {!sellSideAddProperty && <SharingBanner />}

        <LoadingWrapper className="mb-4" isLoading={isCombinedPropertiesLoading} isError={isCombinedPropertiesError}>
          <PropertyCardUIContainer uiChangesFromSell={uiChangesFromSell}>
            <BuyAndSellPropertyCards properties={getCombinedPropertiesSortedByPropertyAddedDateTime(properties)} />
          </PropertyCardUIContainer>
        </LoadingWrapper>

        {!isAnyProperties && !isCombinedPropertiesLoading && <AddPropertyCard />}
        {showCbaYelloTile && <YourOffers />}
      </div>
    )
  }

  const EnhancedHomeInnerSection = ({ children }: { children: React.ReactNode }) => (
    <div className="space-y-4">{children}</div>
  )
  const EnhancedHomeSubtitle = ({ children }: { children: React.ReactNode }) => (
    <div className="text-sm font-medium text-muted-700 sm:text-base">{children}</div>
  )

  const EnhancedHomePageLayout = () => {
    return (
      <div className="space-y-8">
        <EnhancedHomeInnerSection>
          <PropertySearchBar />
          {/* Add lender and CBA pre-approval tile */}
          <NotificationGroup items={items.filter((item) => item.active)} />
          <LoadingWrapper className="mb-4" isLoading={isCombinedPropertiesLoading} isError={isCombinedPropertiesError}>
            <PropertyCardUIContainer uiChangesFromSell={uiChangesFromSell}>
              <BuyAndSellPropertyCards properties={getCombinedPropertiesSortedByPropertyAddedDateTime(properties)} />
            </PropertyCardUIContainer>
          </LoadingWrapper>
          {!isAnyProperties && !isCombinedPropertiesLoading && <AddPropertyCard />}
        </EnhancedHomeInnerSection>

        {showCbaYelloTile && <YourOffers />}
        {isSellSideGetStartedCardVisible && (
          <EnhancedHomeInnerSection>
            <EnhancedHomeSubtitle>Your notifications</EnhancedHomeSubtitle>
            <SellSideNewFeatureBanner
              callbackDismiss={() => {
                acknowledgeSellSideGetStartedCard()
                sellSideNewFeatureBannerDismissed.current = true
                dispatch(
                  trackButtonClick({
                    action: ButtonClickEventNames.SellSideAddPropertyCardDismissed,
                    category: AnalyticsCategories.HomeScreen,
                  })
                )
              }}
              callbackAction={() => {
                router.push('/property/add')
                dispatch(
                  trackButtonClick({
                    action: ButtonClickEventNames.SellSideAddPropertyCardActioned,
                    category: AnalyticsCategories.HomeScreen,
                  })
                )
              }}
            />
          </EnhancedHomeInnerSection>
        )}

        <EnhancedHomeInnerSection>
          <EnhancedHomeSubtitle>Tell us about your experience</EnhancedHomeSubtitle>
          <EmojiFeedbackBanner />
        </EnhancedHomeInnerSection>
      </div>
    )
  }

  return enhanceAddProperty ? <EnhancedHomePageLayout /> : <LegacyHomePageLayout />
}
