import React from 'react'
import { getLegalTeamImage } from '@data/lawyer-content'
import { LoadingWrapper } from '@elements/status-handlers/loading-wrapper'
import { TeamCard } from '@features/home/components/team-card'
import useActiveHbj from '@hooks/useActiveHbj'
import useActiveWorkParty from '@hooks/useActiveWorkParty'
import { useGetTeamQuery } from '@redux/apis/home'
import { getBaIcon } from '@utils/helpers/icon.helpers'

export enum TeamImages {
  CBAGrp = '/images/team-members/cba_logo.svg',
  NoLender = '/images/team-members/nolenderimg.png',
  GenericLawFirm = '/images/team-members/generic-law-firm.svg',
}

export enum TeamIcons {
  CBAGrp = '/logos/CBA.svg',
  'Home-in' = '/images/team-members/logo_app_small.svg',
  NoLender = '/images/team-members/nolenderimg.png',
  nonCBALender = '/images/team-members/nolenderimg.svg',
  CBAWithText = '/logos/CBAWithText.svg',
  GenericLawFirm = '/images/team-members/generic-law-firm.svg',
}

export const getTeamIcon = (organisation: keyof typeof TeamIcons) => {
  return TeamIcons?.[organisation]
}

const GenericLawFirm = () => (
  <TeamCard
    icon1={{
      name: 'Your Law Firm',
      iconUrl: getTeamIcon('GenericLawFirm'),
      iconWidth: 64,
      iconHeight: 64,
      rounded: true,
    }}
    title="Your Law Firm"
    message="Your conveyancer"
    targeturl="/team/law-firm"
  />
)

export const Team = () => {
  const { activeHbj } = useActiveHbj()
  const { isLoading, error, isError } = useGetTeamQuery()
  const { activeWorkParty } = useActiveWorkParty()
  const isPartnerLawFirmSetup = activeWorkParty?.partner_law_firm?.full_name

  return (
    <LoadingWrapper isLoading={!activeHbj || isLoading || !activeWorkParty} isError={isError} error={error}>
      <div className="space-y-4">
        <TeamCard
          icon1={{
            name: activeWorkParty?.your_personal_contact?.full_name,
            iconUrl: getBaIcon(activeWorkParty?.your_personal_contact?.email),
            iconWidth: 64,
            iconHeight: 64,
            rounded: true,
          }}
          message={activeWorkParty?.your_personal_contact?.type || 'Buying Assistant'}
          title={activeWorkParty?.your_personal_contact?.full_name}
          targeturl="/team/personal-contact"
        />
        {isPartnerLawFirmSetup ? (
          <TeamCard
            icon1={{
              name: activeWorkParty?.partner_law_firm?.full_name,
              iconUrl: getLegalTeamImage(activeWorkParty?.partner_law_firm?.organisation),
              iconWidth: 64,
              iconHeight: 64,
              rounded: true,
            }}
            message={activeWorkParty?.partner_law_firm?.type}
            title={activeWorkParty?.partner_law_firm?.full_name}
            targeturl="/team/law-firm"
          />
        ) : (
          <GenericLawFirm />
        )}

        <TeamCard
          icon1={{
            name: activeWorkParty?.lender?.full_name,
            iconUrl: activeWorkParty?.lender?.organisation === 'CBAGrp' ? TeamImages.CBAGrp : TeamImages.NoLender,
            iconWidth: 64,
            iconHeight: 64,
            rounded: true,
          }}
          message={activeWorkParty?.lender?.type || 'Your Lender'}
          title={
            activeWorkParty?.lender?.full_name === ' '
              ? 'Your Lender'
              : activeWorkParty?.lender?.full_name || 'Your Lender'
          }
          targeturl="/team/lender"
        />
      </div>
    </LoadingWrapper>
  )
}
