interface LawyerContentItem {
  image: string
  termsAndConditions: string
}

export const LAWYER_CONTENT: Record<string, LawyerContentItem> = {
  SLK: {
    image: '/images/team-members/slk_logo.svg',
    termsAndConditions: '/documents/SLK-Engagement-Terms.pdf',
  },
  WL: {
    image: '/images/team-members/wl_logo.svg',
    termsAndConditions: '/documents/WL-Engagement-Terms.pdf',
  },
  GS: {
    image: '/images/team-members/gslogo.svg',
    termsAndConditions: '/documents/GS-Cost-and-Engagement-Terms-2024-buy-and-sell.pdf',
  },
  // FL stands for first legal which is the test law firm
  // I just added this so people don't get confused when testing with home-in email
  FL: {
    image: '/images/team-members/test-lawyer.png',
    termsAndConditions: '/documents/TestPDFfile.pdf',
  },
  'D&C': {
    image: '/images/team-members/dottandcrosslogo.svg',
    termsAndConditions: '/documents/D&C-Cost-and-Engagement-Terms_v1.pdf',
  },
  GenericLawFirm: {
    image: '/images/team-members/generic-law-firm.svg',
    termsAndConditions: '',
  },
  Sell: {
    image: '/images/team-members/laurae_from_galilee.png',
    termsAndConditions: '/documents/GS-Cost-and-Engagement-Terms-2024-buy-and-sell.pdf',
  },
}

export const getLegalTeamImage = (organisation: string | undefined): string =>
  LAWYER_CONTENT?.[organisation || '']?.image || LAWYER_CONTENT.GenericLawFirm.image

export const getLegalTeamTermsAndConditions = (organisation: string | undefined): string =>
  LAWYER_CONTENT?.[organisation || '']?.termsAndConditions || ''
