import MoneyOffCsredRoundedIcon from '@mui/icons-material/MoneyOffCsredRounded'
import React from 'react'
import { Button } from '@elements/button'

export const ZeroFeesCallToAction = ({
  disabled = false,
  isLoading = false,
  buttonText,
  form,
}: {
  buttonText: string
  isLoading?: boolean
  disabled?: boolean
  form?: string
}) => (
  <div className="flex items-center justify-between p-5 sm:p-6">
    <div className="flex items-center">
      <div className="text-background-dark-300">
        <MoneyOffCsredRoundedIcon />
      </div>
      <div className="mx-3 text-sm text-background-dark-300 sm:text-base">
        Zero fees until you request a contract preparation.
      </div>
    </div>
    <div className="shrink-0">
      <Button type="submit" muiSize="small" isLoading={isLoading} disabled={disabled} form={form}>
        {buttonText}
      </Button>
    </div>
  </div>
)
