import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Card } from '@elements/card'
import { IconButton, Icons } from '@elements/icons/icon'
import Illustration, { Illustrations } from '@elements/icons/illustration'
import { classNames } from '@utils/helpers/classNameHelper'

interface NotificationItemProps {
  title?: string
  message?: string
  illustration?: Illustrations
  callback?: () => void
  index?: number
  length?: number
  active?: boolean
  component?: JSX.Element
}

export type NotificationItem = PropsWithChildren<NotificationItemProps>

const Item = (props: NotificationItem) => {
  return (
    <div className="cursor-pointer" role="listitem" onClick={props.callback}>
      <Card className="flex" size="small">
        {props.children ?? (
          <ItemInterior
            illustration={props.illustration!}
            title={props.title!}
            message={props.message!}
            callback={props.callback}
          />
        )}
      </Card>
    </div>
  )
}

const ItemInterior = (props: Partial<NotificationItemProps>) => {
  return (
    <>
      <Illustration
        asset={props.illustration || Illustrations.alert}
        className="mr-4 max-w-[24px] shrink-0 self-start md:max-w-full"
      />
      <div className="flex-1">
        <div className="m-0 mb-1 p-0 font-semibold lg:text-base">{props.title}</div>
        <div className="m-0 p-0 text-sm leading-normal">{props.message}</div>
      </div>
      {!!props.callback && (
        <IconButton
          size={12}
          asset={Icons.chevronRight}
          className="ml-auto text-secondary transition group-hover:translate-x-1"
        />
      )}
    </>
  )
}

export const NotificationGroup = ({ max = 2, items }: { max?: number; items: NotificationItem[] }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [opened, setOpened] = useState(false)
  const [height, setHeight] = useState<string>('0px')

  useEffect(() => {
    setHeight(opened ? `${ref.current?.scrollHeight || 0}px` : '0px')
  }, [opened])

  if (!items?.length) return null

  return (
    <div className="mb-2 flex flex-col md:mb-4" role="list">
      {(max < items.length || opened) && (
        <div className="inline-flex items-center text-base tabular-nums leading-[36px] text-muted">
          <hr className="mr-4 flex-1 border-muted-100" />
          Showing {opened ? items.length : max} of {items.length}
          <IconButton
            asset={opened ? Icons.cancel : Icons.add}
            size={12}
            className="inline text-muted hover:text-secondary"
            callback={() => setOpened(!opened)}
          />
          <hr className="ml-4 flex-1 border-muted-100" />
        </div>
      )}
      <div className="rounded bg-white shadow">
        {items.slice(0, max).map((item, idx) =>
          !!item.component ? (
            <Item key={idx} index={idx} length={items.length}>
              {item.component}
            </Item>
          ) : (
            <Item key={idx} {...item} index={idx} length={items.length} />
          )
        )}
        <div
          ref={ref}
          className="overflow-hidden transition-all duration-200 ease-[cubic-bezier(0.19,1,0.22,1)]"
          style={{ height: height }}
        >
          {items.length !== max &&
            items.slice(max - items.length).map((item, idx) =>
              item.component ? (
                <Item key={idx} index={idx} length={items.length}>
                  {item.component}
                </Item>
              ) : (
                <Item key={idx} {...item} index={idx} length={items.length} />
              )
            )}
        </div>
      </div>
    </div>
  )
}
