import { Address, DomainAddress, GetHomeBuyingJourneyHbj, State } from '@home-in/models'
import { useEffect, useState } from 'react'
import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'
import { trackEvent } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { getActiveTransactionForBuyProperty } from '@utils/helpers/buy-properties.helpers'

interface UseGetHbjIdToAddPropertyProps {
  address: DomainAddress | undefined
  manual_address: Address | undefined
  hbj: GetHomeBuyingJourneyHbj[]
  propertyData: WaratahPropertyWithChecklist[] | undefined
}

export const useGetHbjIdToAddProperty = ({
  address,
  hbj,
  manual_address,
  propertyData,
}: UseGetHbjIdToAddPropertyProps) => {
  const [hbjIdToAddProperty, setHbjIdToAddProperty] = useState<string>()
  const [errorSlugToAddProperty, setErrorSlugToAddProperty] = useState<string | undefined>(undefined)
  const [errorMessageToAddProperty, setErrorMessageToAddProperty] = useState<string | undefined>(undefined)

  useEffect(() => {
    const state = (address?.addressComponents?.state || manual_address?.state) as State
    const { hbjId, waitingForData, errorMessage, errorSlug } = getActiveTransactionForBuyProperty({
      hbjData: { hbj },
      state,
      propertyData,
    })

    if (!waitingForData) {
      if (hbjId) {
        setHbjIdToAddProperty(hbjId)
        setErrorSlugToAddProperty(undefined)
        setErrorMessageToAddProperty(undefined)
      } else {
        setErrorSlugToAddProperty(errorSlug)
        setErrorMessageToAddProperty(errorMessage)
      }
    }
  }, [address, manual_address?.state, hbj, propertyData])

  const validToAddProperty = !errorSlugToAddProperty

  return {
    hbjIdToAddProperty,
    validToAddProperty,
    errorSlugToAddProperty,
    errorMessageToAddProperty,
  }
}

interface UseFireMixpanelErrorForNoHbjIdProps {
  address: DomainAddress | undefined
  dispatch: any
  errorSlugToAddProperty: string | undefined
}

export const useFireMixpanelErrorForNoHbjId = ({
  address,
  dispatch,
  errorSlugToAddProperty,
}: UseFireMixpanelErrorForNoHbjIdProps) => {
  useEffect(() => {
    if (errorSlugToAddProperty) {
      dispatch(
        trackEvent({
          action: `Add Property Error: ${errorSlugToAddProperty}`,
          category: AnalyticsCategories.AddProperty,
          value: address?.address || '',
        })
      )
    }
  }, [errorSlugToAddProperty, address])
}
