import { DomainPropertyResponse } from '@home-in/models'
import { useEffect } from 'react'
import { useLazyGetPropertyQuery } from '@redux/apis/domain'

export const useGetDomainPropertyData = (
  domainPropertyId: string | null | undefined
): { domainPropertyData: DomainPropertyResponse | undefined; isLoading: boolean } => {
  // Get property info from Domain.com.au API
  const [getProperty, { data: domainPropertyData, isLoading }] = useLazyGetPropertyQuery()
  useEffect(() => {
    if (!domainPropertyId) return
    getProperty(domainPropertyId)
  }, [domainPropertyId])

  return { domainPropertyData, isLoading }
}
