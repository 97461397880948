import { Chip } from '@mui/material'
import Link from 'next/link'
import React from 'react'
import { Card } from '@elements/card'

export const SellSideNewFeatureBanner = ({
  callbackDismiss,
  callbackAction,
}: {
  callbackDismiss: () => any
  callbackAction: () => any
}) => {
  return (
    <Card className="mb-2 flex items-center sm:mb-4" size="small">
      <div>
        <div className="space-y-4">
          <Chip
            size="small"
            color="warning"
            variant="outlined"
            label="New feature"
            sx={{ border: '1px solid #bd5a00', color: '#bd5a00' }}
          />
          <div>
            <div className="mb-2 text-base font-semibold sm:text-lg">Selling your NSW property?</div>
            <p className="max-w-md pb-0 text-sm sm:text-sm">
              Request a contract of sale and get ready to sell in no time.
            </p>
          </div>
          <div className="flex space-x-4">
            <Link href="#">
              <a
                onClick={callbackAction}
                className="cursor-pointer text-sm text-primary-700 hover:text-primary-800 sm:text-base"
              >
                Add your property
              </a>
            </Link>
            <Link href="#">
              <div
                onClick={callbackDismiss}
                className="cursor-pointer text-sm text-primary-700 hover:text-primary-800 sm:text-base"
              >
                Not now{' '}
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="ml-2 w-24 shrink-0 grow-0 sm:ml-4 lg:ml-12 lg:w-32">
        <img src="illustrations/house.svg" alt="House illustration" />
      </div>
    </Card>
  )
}
