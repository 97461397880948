import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { Card } from '@elements/card'
import { Icon, Icons } from '@elements/icons/icon'
import { ProfilePicture } from '@elements/profile-picture'
import { classNames } from '@utils/helpers/classNameHelper'

interface BannerItemIcon {
  iconUrl?: string
  name?: string
  iconWidth?: number
  iconHeight?: number
  rounded?: boolean
}

interface ParentCompProps {
  icon1: BannerItemIcon
  icon2?: React.ReactNode
  message?: string
  targeturl: string
  title?: string
}

export const TeamCard = ({ icon1, icon2, message, targeturl, title }: ParentCompProps) => {
  const hasTitle = title && title?.length > 0

  return (
    <Card className="mb-4 py-4 px-4 duration-200 hover:shadow-md active:shadow-sm md:py-4 md:px-4">
      <Link href={targeturl}>
        <a className="relative flex text-left">
          <div
            className={classNames('relative flex mr-5 overflow-hidden shrink-0 self-center w-12 h-12', {
              'rounded-full': icon1?.rounded,
            })}
          >
            {icon1?.iconUrl ? (
              <Image src={icon1.iconUrl} layout="fill" alt="banner-icon" className="h-10 w-10" unoptimized />
            ) : (
              <ProfilePicture name={icon1?.name} variant="teamCard" />
            )}
          </div>
          <div className="flex grow flex-col justify-center pr-8">
            {hasTitle && <div className="mb-2 text-lg font-bold md:mb-1">{title}</div>}
            <div className={classNames(hasTitle ? 'text-muted text-base' : 'text-lg')}>{message}</div>
          </div>
          <div className="flex shrink-0">
            {icon2 && (
              <div className={classNames('flex mr-5 overflow-hidden self-center rounded-full: true')}>
                <div>{icon2}</div>
              </div>
            )}
            <div className="w-8 self-center text-center">
              <Icon asset={Icons.chevronRight} className="mx-auto text-muted" size={12} />
            </div>
          </div>
        </a>
      </Link>
    </Card>
  )
}
