import { Actor } from '@home-in/models'

const mapActorToIcon = (val: Actor) => {
  if (val === Actor.CONVEYANCER) {
    // FIXME: More law firms in the future??
    return '/images/icons/Galilee.png'
  }
  if (val === Actor.LENDER || val === Actor.FINANCIAL_INSTITUTION) {
    // FIXME: Non-CBA Lender how to handle?
    return '/images/icons/CBA.png'
  }
  if (val === Actor.OPERATOR) {
    return '/images/icons/home-in/104pt.png'
  }
  if (val === Actor.CUSTOMER) {
    // This should return user's Avatar image
    return '/images/icons/home-in/104pt.png'
  }
  return '/images/icons/home-in/71pt.png'
}

const getBaIcon = (email: string | undefined) => {
  // used sha1 in misc.ts file to generated the sha1 hash of the email
  const baEmailToIconMapping: { [key: string]: string } = {
    'ashley.flynn@home-in.com.au': '1480dd82b4aaba35253a6a7ac8298ba9e2ccdeb3.png',
    'corey.ngo@home-in.com.au': '066a6a418191f591d4a6801eae310f973fc77e17.png',
    'diana.ashoty@home-in.com.au': '1291652be3c197730afbbc6ba31747c814fe1b92.png',
    'georga.prout@home-in.com.au': '104d59adf9973e219fa6a3494b33adc7b00786c6.png',
    'dominic.coorey@home-in.com.au': 'f3586af5f9a3e633f38f4afe4be978bce87be500.png',
    'shaheen.ali@home-in.com.au': 'aba6e2011e8a062d406d59a4d3981cd5c5527688.png',
  }

  const imgName = baEmailToIconMapping[email || 'no_email'] || 'defaultBA.png'
  return `/images/icons/${imgName}`
}

export { mapActorToIcon, getBaIcon }
