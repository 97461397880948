import { USER_METADATA_ENUM } from '@home-in/models'
import { useAddUserMetadataMutation } from '@redux/apis/profile'

/** Persist user acknowledgement/selection into db table */
export const useAcknowledgeSellSideGetStartedCard = () => {
  const [addUserMetadata, { isError, isLoading, isSuccess }] = useAddUserMetadataMutation()

  const acknowledgeSellSideGetStartedCard = async () => {
    return await addUserMetadata({
      metadata_type: USER_METADATA_ENUM.acknowledgeSellSideGetStartedCard,
      unique_metadata_id: 'default',
      value: true,
    })
  }

  return { acknowledgeSellSideGetStartedCard, isError, isLoading, isSuccess }
}
