import { MuscovyPropertySchemaType } from '@home-in/models'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import React, { FC } from 'react'
import { Card } from '@elements/card'
import { Icon, Icons } from '@elements/icons/icon'
import { generateTrashIconId } from '@features/home/utils/generate-trash-icon'

const TrashIcon = ({ id, handleRemoveClick }: { id: string; handleRemoveClick: () => void }) => (
  <div
    onClick={(e) => {
      e.stopPropagation() // Prevents the click event from bubbling up to the parent Link component
      handleRemoveClick()
    }}
    className="cursor-pointer rounded-full text-black transition-all duration-300 hover:text-error"
    id={id}
  >
    <Icon asset={Icons.trash} size={24} />
  </div>
)

export enum PropertyCardType {
  buy = 'buy',
  sell = 'sell',
}

interface PropertyCardUI {
  link: string
  handleRemoveClick: () => void
  propertyAddress: string
  cardSubtitle?: string
  imgSource: string | StaticImageData
  uiChangesFromSell: boolean
  sellChecklists: MuscovyPropertySchemaType[]
  propertyType: PropertyCardType
}

export const PropertyCardUI: FC<PropertyCardUI> = ({
  link,
  handleRemoveClick,
  propertyAddress,
  cardSubtitle,
  imgSource,
  uiChangesFromSell,
  sellChecklists,
}) => {
  return (
    <Link
      href={{
        pathname: link,
      }}
    >
      {/* Role is used by the smoke test add property selector */}
      <div data-testid="addPropertyCard">
        <Card className="relative mb-2 flex cursor-pointer flex-col overflow-hidden p-4 text-secondary hover:text-primary md:mb-4 md:flex-row md:p-0">
          <div className="relative -m-4 mb-0 h-56 min-h-[14rem] md:m-0 md:h-auto md:w-1/2">
            <div className="absolute top-3 right-3 z-10 block rounded-full bg-white p-1 md:hidden">
              <TrashIcon id={generateTrashIconId(true, propertyAddress)} handleRemoveClick={handleRemoveClick} />
            </div>
            <Image src={imgSource} alt={propertyAddress} layout="fill" unoptimized className="object-cover" />
          </div>

          <div className="-mr-4 flex grow flex-col justify-between bg-white pt-4 pr-4 md:mr-0 md:w-1/2 md:pb-6 md:pl-6">
            {uiChangesFromSell && sellChecklists?.length > 0 ? (
              <div className="justify-between border-b border-background pb-4">
                <div>
                  <div className="flex justify-between">
                    <div className="text-lg font-bold text-black">{propertyAddress}</div>
                    <div className="hidden md:block">
                      <TrashIcon
                        id={generateTrashIconId(false, propertyAddress)}
                        handleRemoveClick={handleRemoveClick}
                      />
                    </div>
                  </div>
                  <div className="mt-3 flex items-center gap-4 text-sm text-black">
                    {cardSubtitle && <div className="rounded-md bg-light p-1 py-1">{cardSubtitle}</div>}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-between border-b border-background pb-4 pl-2">
                <div>
                  <div className="mb-1 flex">
                    <div className="text-lg font-bold text-black">{propertyAddress}</div>
                  </div>
                  {cardSubtitle && <div className="text-sm text-black">{cardSubtitle}</div>}
                </div>
                <div className="hidden md:block">
                  <TrashIcon id={generateTrashIconId(false, propertyAddress)} handleRemoveClick={handleRemoveClick} />
                </div>
              </div>
            )}

            <div className="mt-4 -mb-4 w-full text-center">
              <a className="block w-full rounded p-4 text-base font-semibold  transition-all active:bg-light">
                View full checklist
              </a>
            </div>
          </div>
        </Card>
      </div>
    </Link>
  )
}

export const PropertyCardUIContainer: FC<{ uiChangesFromSell: boolean }> = ({ children, uiChangesFromSell }) =>
  uiChangesFromSell ? <div className="grid gap-3 sm:grid-cols-2 lg:grid-cols-3">{children}</div> : <div>{children}</div>

export const PropertyCardTag: FC<{}> = ({ children }) => (
  <div className="rounded-lg bg-light py-1.5 px-2 text-sm text-black">{children}</div>
)

const PropertyCardUISellSideItem: FC<PropertyCardUI> = ({
  link,
  handleRemoveClick,
  propertyAddress,
  cardSubtitle,
  imgSource,
  propertyType,
}) => {
  const purchaseTypeString = propertyType === PropertyCardType.buy ? 'Buy Property' : 'Sell Property'
  return (
    <Card className="cursor-pointer text-primary-600 hover:text-primary sm:text-black">
      <Link
        href={{
          pathname: link,
        }}
      >
        {/* Role is used by the smoke test add property selector */}
        <div data-testid="addPropertyCard">
          <div className="relative mb-3 h-56 min-h-[14rem] overflow-hidden rounded">
            <div className="absolute top-3 right-3 z-10 block rounded-full bg-white p-1">
              <TrashIcon id={generateTrashIconId(true, propertyAddress)} handleRemoveClick={handleRemoveClick} />
            </div>
            <Image src={imgSource} alt={propertyAddress} layout="fill" unoptimized className="object-cover" />
          </div>

          <div className="mb-1 flex">
            <div className="text-lg font-bold text-black">{propertyAddress}</div>
          </div>
          <div className="mt-3 flex items-center gap-4 text-sm text-black">
            <PropertyCardTag>{purchaseTypeString}</PropertyCardTag>
            {cardSubtitle && <PropertyCardTag>{cardSubtitle}</PropertyCardTag>}
          </div>
          <a className="block w-full rounded py-2 text-sm font-semibold  transition-all active:bg-light">
            View full checklist
          </a>
        </div>
      </Link>
    </Card>
  )
}

interface PropertyCardUIWrapperProps extends PropertyCardUI {
  uiChangesFromSell: boolean
}

export const PropertyCardUIWrapper: FC<PropertyCardUIWrapperProps> = ({
  link,
  cardSubtitle,
  handleRemoveClick,
  imgSource,
  propertyAddress,
  sellChecklists,
  propertyType,
  uiChangesFromSell,
}) => {
  return uiChangesFromSell ? (
    <PropertyCardUISellSideItem
      link={link}
      cardSubtitle={cardSubtitle}
      handleRemoveClick={handleRemoveClick}
      imgSource={imgSource}
      propertyAddress={propertyAddress}
      sellChecklists={sellChecklists}
      uiChangesFromSell={uiChangesFromSell}
      propertyType={propertyType}
    />
  ) : (
    <PropertyCardUI
      link={link}
      cardSubtitle={cardSubtitle}
      handleRemoveClick={handleRemoveClick}
      imgSource={imgSource}
      propertyAddress={propertyAddress}
      sellChecklists={sellChecklists}
      uiChangesFromSell={uiChangesFromSell}
      propertyType={propertyType}
    />
  )
}
