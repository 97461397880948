import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'
import { useGetChecklists } from './useGetChecklists'

interface filterOptions {
  taskRef: string
  completed?: boolean
}

export interface FoundHBJ {
  hbjId: string
  imageUrl: string
  propertyId: string
}

/**
 * Get a list of HBJs where a task matches {@link taskRef} & {@link completed}
 * @param taskRef Example 'ST10'
 */
export const useGetHBJsByTask = ({ taskRef, completed }: filterOptions): FoundHBJ[] | null => {
  const checklists = useGetChecklists()
  if (!checklists) return null
  const foundHbjs = filterHbjsByTask(checklists, { taskRef, completed })
  return foundHbjs
}

export const filterHbjsByTask = (
  properties: WaratahPropertyWithChecklist[],
  { taskRef, completed = false }: filterOptions
) => {
  const hbjIds = properties
    .filter((property) => !property.propertyArchived)
    .reduce((acc, property) => {
      const foundTask = property.tasks?.find((task) => task.taskRef === taskRef && task.completed === completed)
      if (foundTask)
        acc.push({
          hbjId: property.hbjId,
          imageUrl: property.imageURL,
          propertyId: property.propertyId,
        })
      return acc
    }, [] as FoundHBJ[])

  return hbjIds
}
