import { useFlags } from 'launchdarkly-react-client-sdk'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { AddPropertyBanner } from '@elements/banners/add-property-banner'
import { Illustrations } from '@elements/icons/illustration'
import { LinkCard } from './link-card'

export const AddPropertyCard: FC = () => {
  const router = useRouter()
  const { sellSideAddProperty } = useFlags()
  if (sellSideAddProperty) {
    return (
      <AddPropertyBanner
        heading="Buying or selling a property?"
        content="Add a property to unlock your personalised checklist, engage your conveyancer and request a contract review or contract preparation. "
        callback={() => router.push('/property/add')}
      />
    )
  }

  return <AddPropertyBanner callback={() => router.push('/property/add')} />
}
