import { MuscovyPropertySchemaType, State, PropertySaleType, typeOfSale } from '@home-in/models'
import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'
import { CombinedPropertiesList } from '@hooks/useProperties'
import { SellChecklist } from '@redux/reducers/sell'
import {
  isMergedHbjAndChecklist,
  isMergedSellPropertyAndChecklist,
  MergedHbjAndChecklist,
  MergedSellPropertyAndChecklist,
} from '@utils/helpers/properties.helpers'
import { userHasRequestedContractPreparation } from './helper'

export type SellProperty = {
  type: string
  propertyAddedDateTime?: string
  propertyId: string
  address: string
  state: State
  imageUrl?: string
  addressKey: string
  removable: boolean
}

export type BuyProperty = {
  type: string
  propertyAddedDateTime?: string
  propertyId: string
  address: string
  auctionDate: string
  contractExchanged: boolean
  imageUrl: string
  saleType: typeOfSale
  settlementDate: string
  state: State
  removable?: boolean
  hbjId: string
}

export type CombinedProperty = SellProperty | BuyProperty

export const prepareSellPropertiesDataForUI = ({
  sellProperties,
  sellChecklists,
}: {
  sellProperties: MuscovyPropertySchemaType[]
  sellChecklists: (SellChecklist | undefined)[]
}): SellProperty[] => {
  return sellProperties?.map((data) => {
    const propertyChecklist = sellChecklists?.find((checklist) => checklist?.propertyId === data.propertyId)
    const removable = !userHasRequestedContractPreparation(propertyChecklist)
    return {
      type: 'sell',
      propertyAddedDateTime: data.propertyAddedDateTime,
      propertyId: data.propertyId,
      address: data.address,
      state: data.state,
      imageUrl: data?.imageUrl || undefined,
      addressKey: data?.address,
      removable,
    }
  })
}

export const prepareBuyPropertiesDataForUI = ({
  buyProperties,
}: {
  buyProperties: WaratahPropertyWithChecklist[]
}): BuyProperty[] => {
  return buyProperties.map((data) => ({
    type: 'buy',
    propertyAddedDateTime: data.propertyAddedDateTime,
    propertyId: data.propertyId,
    address: data.address,
    auctionDate: data.auctionDateTime,
    contractExchanged: data.contractSigned,
    imageUrl: data.imageURL,
    saleType: data.saleType === PropertySaleType.Auction ? 'auction' : ('private_treaty' as typeOfSale),
    settlementDate: data.settlementDateTime,
    state: data.state,
    removable: data.removable,
    hbjId: data.hbjId,
  }))
}

export const getCombinedBuyAndSellProperties = (propertyList: CombinedPropertiesList): CombinedProperty[] => {
  const combinedProperties = propertyList.map((property) => {
    if (isMergedSellPropertyAndChecklist(property)) {
      const { propertyInfo, checklistInfo } = property as MergedSellPropertyAndChecklist
      return prepareSellPropertiesDataForUI({
        sellProperties: [propertyInfo],
        sellChecklists: [checklistInfo],
      })
    }
    if (isMergedHbjAndChecklist(property)) {
      return prepareBuyPropertiesDataForUI({
        buyProperties: [(property as MergedHbjAndChecklist).propertyChecklistInfo],
      })
    }
    return []
  })
  return combinedProperties.flat()
}

export const sortPropertiesByPropertyAddedDateTime = ({
  combinedProperties,
}: {
  combinedProperties: CombinedProperty[]
}) => {
  combinedProperties.sort((a, b) => {
    if (!a.propertyAddedDateTime && !b.propertyAddedDateTime) return 0
    if (!a.propertyAddedDateTime) return 1
    if (!b.propertyAddedDateTime) return -1
    return new Date(b.propertyAddedDateTime!).getTime() - new Date(a.propertyAddedDateTime!).getTime()
  })
}

export const isSellProperty = (data: CombinedProperty): data is SellProperty => {
  return data.type === 'sell'
}

export const isBuyProperty = (data: CombinedProperty): data is BuyProperty => {
  return data.type === 'buy'
}

export const getCombinedPropertiesSortedByPropertyAddedDateTime = (
  properties: CombinedPropertiesList
): CombinedProperty[] => {
  const combinedProperties = getCombinedBuyAndSellProperties(properties)
  sortPropertiesByPropertyAddedDateTime({ combinedProperties })
  return combinedProperties
}
