import { Popper, PopperProps } from '@mui/material'
import React from 'react'
import { AUTOCOMPLETE_POPPER_COMPONENT_STYLE_OVERRIDE } from './autocomplete-style-overrides'

export default function CustomAutocompletePopperComponent({ props }: { props: PopperProps }) {
  return (
    <Popper {...props} sx={AUTOCOMPLETE_POPPER_COMPONENT_STYLE_OVERRIDE}>
      {props.children}
    </Popper>
  )
}
