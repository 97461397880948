import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import BedOutlinedIcon from '@mui/icons-material/BedOutlined'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import HomeIcon from '@mui/icons-material/Home'
import ShowerIcon from '@mui/icons-material/Shower'
import { Dialog, DialogActions, DialogContent, Link, Typography, Divider } from '@mui/material'
import Image from 'next/image'
import React, { FC, useState } from 'react'
import LoadingSpinner from '@elements/loading-spinner'
import { extractDomainHeroImg } from '@utils/helpers/domain-api.helpers'
import { AddPropertyDialogContentProps } from './add-property-dialog'
import { AddPropertyDialogForm } from './add-property-dialog-form'
import { ZeroFeesCallToAction } from './zero-fees-call-to-action'

export const AddPropertyDesktopDialog: FC<AddPropertyDialogContentProps> = ({
  open,
  handleClose,
  domainPropertySuggestion,
  domainPropertyData,
  domainListingData,
  propertyDataIsLoading,
  manual_address,
}) => {
  const propertyImageFromDomain = extractDomainHeroImg(domainPropertyData)
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false)
  const [disableAddPropertyButton, setDisableAddPropertyButton] = useState<boolean>(false)

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby="add_property_dialog_desktop"
      data-testid="add_property_dialog_desktop"
    >
      <DialogContent sx={{ paddingTop: '8px!important' }}>
        <div className="mx-auto flex h-full w-full max-w-7xl flex-row">
          {/* Left Side */}
          <div className="relative w-7/12 overflow-y-scroll">
            <div className={'mb-6 mt-5 px-5'}>
              <Link onClick={handleClose} underline="none" sx={{ color: 'grey' }} className={'flex items-center'}>
                {' '}
                <ArrowBackRoundedIcon className={'cursor-pointer'} />
                <div className={'ml-4 text-xl font-light text-black'}>Tell us about your property</div>
              </Link>
            </div>
            <AddPropertyDialogForm
              domainPropertySuggestion={domainPropertySuggestion}
              domainProperty={domainPropertyData}
              domainListing={domainListingData}
              manualAddress={manual_address}
              handleClose={handleClose}
              setIsFormSubmitting={setIsFormSubmitting}
              isFormSubmitting={isFormSubmitting}
              setDisableAddPropertyButton={setDisableAddPropertyButton}
            />
            <div className="sticky bottom-0 left-0 mt-auto w-full bg-white">
              <Divider />
              <ZeroFeesCallToAction
                buttonText="Get started"
                isLoading={isFormSubmitting}
                disabled={disableAddPropertyButton}
                form="add-property-form"
              />
            </div>
          </div>

          {/* Right Side */}
          <div className="w-5/12 border-l border-background-dark-100 pl-8">
            {propertyDataIsLoading && <LoadingSpinner />}
            {(domainPropertyData || manual_address) && !propertyDataIsLoading && (
              <div className="relative h-full">
                {/* Image Section */}
                <div className="pt-5">
                  <div className="relative h-80 w-full overflow-hidden">
                    <Image
                      src={propertyImageFromDomain || '/icons/no_preview.svg'}
                      layout="fill"
                      objectFit="cover"
                      alt="Property image"
                      className="absolute inset-0"
                      unoptimized
                    />
                  </div>
                </div>
                <div className="pt-5 text-xl font-light text-black">
                  {domainPropertyData?.address || manual_address?.full_address}
                </div>

                <div className="pb-4 pt-4">
                  <Divider />
                </div>

                <div className="text-base font-light text-black">Property Features</div>

                <div className="mt-4 flex">
                  {!manual_address && (
                    <>
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2 text-background-dark-300">
                          <HomeIcon fontSize="medium" />
                          <span>
                            Land size: {domainPropertyData?.areaSize ? `${domainPropertyData?.areaSize}m²` : '--'}
                          </span>
                        </div>
                        <div className="mt-2 flex items-center space-x-2 text-background-dark-300">
                          <DirectionsCarIcon fontSize="medium" />
                          <span>Car space: {domainPropertyData?.carSpaces || '--'}</span>
                        </div>
                      </div>
                      <div className="ml-12 flex flex-col">
                        <div className="flex items-center space-x-2 text-background-dark-300">
                          <BedOutlinedIcon fontSize="medium" />
                          <span>Bedroom: {domainPropertyData?.bedrooms || '--'}</span>
                        </div>
                        <div className="mt-2 flex items-center space-x-2 text-background-dark-300">
                          <ShowerIcon fontSize="medium" />
                          <span>Bathroom: {domainPropertyData?.bathrooms || '--'}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {manual_address && <p className={'text-background-dark-200'}> Not available </p>}
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
