import React from 'react'
import { classNames } from '@utils/helpers/classNameHelper'
import Illustration, { Illustrations } from './icons/illustration'

interface InfoIconProps {
  tabIndex?: number
  customColour?: string
  margin?: boolean
  size?: number
}

export const InfoIcon = ({ margin = true, tabIndex = 0, customColour, size = 20 }: InfoIconProps) => (
  <span
    className={classNames(
      'group block w-5 h-5 rounded-full outline-none ring-2 ring-transparent focus-visible:ring-secondary/50 ring-offset-2 duration-200',
      { 'ml-2': margin }
    )}
    tabIndex={tabIndex}
  >
    <Illustration asset={Illustrations.info} size={size} customColour={customColour} />
  </span>
)
