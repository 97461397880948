import { useAppSelector } from '@redux/hooks'

export const useSellProperties = () => {
  const properties = useAppSelector((state) => state.sell.sellProperties)
  const removedProperties = useAppSelector((state) => state.sell.removedSellProperties)

  const filteredProperties = properties?.filter((property) => {
    const propertyShouldBeRemoved = !!removedProperties?.find(
      (removedProperty) => removedProperty === property?.propertyId
    )

    return !propertyShouldBeRemoved
  })

  return filteredProperties
}
