import { SellChecklistTaskId, SellChecklistStatus } from '@home-in/models'
import { SellChecklist } from '@redux/reducers/sell'

export const userHasRequestedContractPreparation = (propertyChecklist: SellChecklist | undefined): boolean => {
  return !!propertyChecklist?.checklist?.some(
    (checklist) =>
      (checklist.taskId === SellChecklistTaskId.RequestContractPreparation ||
        checklist.taskId === SellChecklistTaskId.RequestContractPreparationV2) &&
      checklist.status === SellChecklistStatus.COMPLETED
  )
}
