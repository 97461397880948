import { Address, DomainListingResponse, DomainProperty, DomainPropertyResponse } from '@home-in/models'
import { useMediaQuery, useTheme } from '@mui/material'
import React, { FC } from 'react'
import { useGetDomainListingData } from '@hooks/useGetDomainListingData'
import { useGetDomainPropertyData } from '@hooks/useGetDomainPropertyData'
import { useAppSelector } from '@redux/hooks'
import { AddPropertyDesktopDialog } from './add-property-dialog-desktop'
import { AddPropertyMobileContent } from './add-property-dialog-mobile'

interface AddPropertyDialogProps {
  open: boolean
  handleClose: () => void
}
export interface AddPropertyDialogContentProps {
  open: boolean
  handleClose: () => void
  domainPropertySuggestion: DomainProperty | undefined
  domainPropertyData: DomainPropertyResponse | undefined
  domainListingData: DomainListingResponse | undefined
  propertyDataIsLoading: boolean
  manual_address: Address | undefined
}

export const AddPropertyDialog: FC<AddPropertyDialogProps> = ({ open, handleClose }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { domain_property, manual_address } = useAppSelector((state) => state.addProperty)
  const { domainPropertyData, isLoading: propertyDataIsLoading } = useGetDomainPropertyData(domain_property?.id)
  const { domainListingData, isLoading: listingDataIsLoading } = useGetDomainListingData(domainPropertyData)

  return (
    <>
      {isMobile ? (
        <AddPropertyMobileContent
          open={open}
          handleClose={handleClose}
          domainPropertySuggestion={domain_property}
          domainPropertyData={domainPropertyData}
          domainListingData={domainListingData}
          manual_address={manual_address}
          propertyDataIsLoading={propertyDataIsLoading}
        />
      ) : (
        <AddPropertyDesktopDialog
          open={open}
          handleClose={handleClose}
          domainPropertySuggestion={domain_property}
          domainPropertyData={domainPropertyData}
          domainListingData={domainListingData}
          manual_address={manual_address}
          propertyDataIsLoading={propertyDataIsLoading}
        />
      )}
    </>
  )
}
