import { State } from '@home-in/models'
import React, { FC } from 'react'
import { Listbox, TextField } from '@elements/forms'
import { enumToSelectOptions } from '@utils/helpers/enum.helpers'
import { ManualAddressVariant } from './address-group'
import { ManualAddressProps } from './manual-address'

const variantStyles: Record<ManualAddressVariant, string> = {
  default: 'grid grid-cols-2 gap-4',
  'single-column': 'grid grid-cols-1 gap-4',
}

export const ManualAddressV2: FC<ManualAddressProps> = ({
  name,
  control,
  restrictState,
  floatingLabels = true,
  errorDisplayType = 'text',
  restrictStateErrorMessage = '',
  variant = 'default',
}) => {
  return (
    <>
      <div className={variantStyles[variant]}>
        <TextField
          label="Unit/Street number"
          floatingLabel={floatingLabels}
          placeholder={floatingLabels ? ' ' : 'L1/1'}
          className="col-span-2 lg:col-span-1"
          name={`${name}.street_number`}
          required
          hasRequiredLabel
          control={control}
        />

        <TextField
          label="Street name"
          floatingLabel={floatingLabels}
          placeholder={floatingLabels ? ' ' : 'Locomotive St'}
          className="col-span-2 lg:col-span-1"
          name={`${name}.street_name`}
          required
          hasRequiredLabel
          control={control}
        />

        <TextField
          label="Suburb"
          floatingLabel={floatingLabels}
          placeholder={floatingLabels ? ' ' : 'Eveleigh'}
          name={`${name}.suburb`}
          className="col-span-2 lg:col-span-1"
          required
          hasRequiredLabel
          control={control}
        />

        <Listbox
          label="State"
          floatingLabel={floatingLabels}
          className="col-span-2 lg:col-span-1"
          name={`${name}.state`}
          defaultEmpty
          control={control}
          values={enumToSelectOptions(State)}
          rules={
            restrictState && {
              validate: {
                validateManualAddress: (value: State) => (value !== restrictState ? restrictStateErrorMessage : true),
              },
            }
          }
          errorDisplayType={errorDisplayType}
        />

        <TextField
          label="Postcode"
          floatingLabel={floatingLabels}
          maxLength={4}
          placeholder={floatingLabels ? ' ' : '2015'}
          name={`${name}.postcode`}
          className="col-span-2 lg:col-span-1"
          required
          hasRequiredLabel
          control={control}
          rules={{
            pattern: { value: /\d{4}/, message: 'Postcode must be 4 digits' },
          }}
        />
      </div>
    </>
  )
}
