import { Address, DomainProperty, DomainPropertyResponse, State, TransactionType, YesNo } from '@home-in/models'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { NextRouter } from 'next/router'
import { IntercomContextValues } from 'react-use-intercom'
import { fireToastNotifications } from '@redux/reducers/notifications'
import { mergeAddress } from '@redux/utils/utils'
import { extractDomainHeroImg } from '@utils/helpers/domain-api.helpers'
import { AddPropertyDialogFormFields } from './add-property-dialog-form'

interface AddBuyPropertyProps {
  router: NextRouter
  address: Address | undefined
  form: AddPropertyDialogFormFields
  dispatch: Dispatch<AnyAction>
  hbjIdToAddProperty?: string
  addBuyProperty: MutationTrigger<any>
  imageUrl: string
}

const addBuyPropertyHandler = async ({
  form,
  dispatch,
  hbjIdToAddProperty,
  addBuyProperty,
  address,
  imageUrl,
  router,
}: AddBuyPropertyProps) => {
  const result = addBuyProperty({
    hbj_id: hbjIdToAddProperty!,
    address_key: address?.id || '',
    sale_type: form.saleType,
    are_contracts_exchanged: form.contractOfSale === YesNo.Yes,
    property_type: form.propertyType,
    has_cos_ready: form.hasCosReady === YesNo.Yes,
    property_image_url: imageUrl,
    // @ts-ignore // fixing this separately.
    address: {
      ...address,
      state: address?.state as State,
    },
    auction_date: form.auctionDate ? new Date(form.auctionDate).toISOString() : '',
  })

  dispatch(
    fireToastNotifications({
      result,
      successMessage: 'Added property',
      errorMessage: 'Error adding property',
    })
  )
  const addBuyPropertyResponse = (await result.unwrap()) as any
  router.push(`/checklist/${addBuyPropertyResponse?.hbjId}/${addBuyPropertyResponse?.propertyId}`)
}

interface AddSellPropertyProps {
  router: NextRouter
  address: Address | undefined
  form: AddPropertyDialogFormFields
  dispatch: Dispatch<AnyAction>
  addSellProperty: any
  addSellChecklist: any
  imageUrl: string
  intercom: IntercomContextValues
  domainPropertySuggestion?: DomainProperty
}

const addSellPropertyHandler = async ({
  router,
  address,
  form,
  dispatch,
  addSellProperty,
  addSellChecklist,
  imageUrl,
  intercom,
  domainPropertySuggestion,
}: AddSellPropertyProps) => {
  const addSellPropertyResponse = await addSellProperty({
    address: address?.full_address || '',
    state: address?.state as State,
    propertyArchived: false,
    addressComponents: {
      state: address?.state as State,
      suburb: address?.suburb || '',
      postcode: address?.postcode || '',
      manualEntry: address?.manual_entry || false,
      streetNumber: address?.street_number || '',
      streetName: address?.street_name || '',
      streetType: domainPropertySuggestion?.addressComponents?.streetType || '',
      streetTypeLong: domainPropertySuggestion?.addressComponents?.streetTypeLong || '',
      unitNumber: domainPropertySuggestion?.addressComponents?.unitNumber || undefined,
    },
    transactionType: form.transactionType,
    imageUrl,
  }).unwrap()
  const result = addSellChecklist({ propertyId: addSellPropertyResponse?.propertyId })

  dispatch(
    fireToastNotifications({
      result,
      onlyShowError: true,
      errorMessage: 'Error adding property',
    })
  )
  await result
  intercom.update({
    customAttributes: {
      'Sell Phase': 'Added a sell property',
    },
  })
  router.push(`/sell/checklist/${addSellPropertyResponse.propertyId}`)
}

interface SubmitAddPropertyFormParams {
  router: NextRouter
  intercom: IntercomContextValues
  form: AddPropertyDialogFormFields
  domainPropertySuggestion?: DomainProperty
  domainProperty?: DomainPropertyResponse
  manualAddress?: Address
  dispatch: Dispatch<AnyAction>
  // Add buy property specific stuff
  hbjIdToAddProperty?: string
  addBuyProperty: MutationTrigger<any>
  // Add sell property specific stuff
  addSellProperty: any
  addSellChecklist: any
}

export const submitAddPropertyForm = async ({
  form,
  domainPropertySuggestion,
  domainProperty,
  manualAddress,
  dispatch,
  hbjIdToAddProperty,
  addBuyProperty,
  addSellProperty,
  addSellChecklist,
  router,
  intercom,
}: SubmitAddPropertyFormParams): Promise<void> => {
  const isManual = !!manualAddress
  // TODO: Is mergeAddress really needed?
  const address = mergeAddress({
    isManual,
    manualAddress,
    domainAddress: {
      id: domainPropertySuggestion?.id,
      address: domainPropertySuggestion?.address,
      addressComponents: domainPropertySuggestion?.addressComponents,
    },
  })
  const imageUrl = extractDomainHeroImg(domainProperty)

  if (form.transactionType === TransactionType.Buying) {
    await addBuyPropertyHandler({ address, form, dispatch, hbjIdToAddProperty, addBuyProperty, imageUrl, router })
  } else {
    await addSellPropertyHandler({
      address,
      form,
      dispatch,
      addSellProperty,
      addSellChecklist,
      imageUrl,
      intercom,
      router,
      domainPropertySuggestion,
    })
  }
}
