import {
  Address,
  DomainListingResponse,
  DomainProperty,
  DomainPropertyResponse,
  MuscovyPropertySchemaType,
  PropertySaleType,
  PropertyType,
  TransactionType,
} from '@home-in/models'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { mergeAddress } from '@redux/utils/utils'
import { AddPropertyDialogFormFields } from './add-property-dialog-form'

export const usePrefillPropertyTypeWithDomainPropertyData = (
  methods: UseFormReturn<AddPropertyDialogFormFields>,
  domainProperty?: DomainPropertyResponse
) => {
  useEffect(() => {
    if (!domainProperty) return
    methods.setValue('propertyType', PropertyType[domainProperty?.propertyType as keyof typeof PropertyType], {
      shouldTouch: true,
      shouldValidate: true,
    })
  }, [domainProperty])
}

export const usePrefillFormWithDomainListingData = (
  methods: UseFormReturn<AddPropertyDialogFormFields>,
  listing: DomainListingResponse | undefined
) => {
  useEffect(() => {
    if (listing?.saleDetails?.saleMethod === 'auction' && listing?.saleDetails?.auctionDetails?.auctionSchedule) {
      methods.setValue('saleType', PropertySaleType.Auction, { shouldTouch: true })
      const date = new Date(listing?.saleDetails?.auctionDetails?.auctionSchedule?.openingDateTime || 'invalid')
      // Convert the UTC time given to the local time based on the users system clock
      const dateWithLocalTimezoneOffset = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      const isoDateStringWithoutSeconds = (dateWithLocalTimezoneOffset.toISOString() as string).split('.')?.[0]
      if (!isNaN(date.getTime())) {
        // Auction Date input field does not support seconds
        methods.setValue('auctionDate', isoDateStringWithoutSeconds, { shouldTouch: true })
      }
    }
    if (listing?.saleDetails?.saleMethod === 'privateTreaty') {
      methods.setValue('saleType', PropertySaleType.PrivateTreaty, { shouldTouch: true })
    }
  }, [listing])
}

export const useResetAuctionDate = (
  methods: UseFormReturn<AddPropertyDialogFormFields>,
  saleType?: PropertySaleType
) => {
  // Reset auction date if sale type changes
  useEffect(() => {
    if (saleType !== PropertySaleType.Auction) methods.resetField('auctionDate')
  }, [saleType])
}

export const isSellAddressAlreadyAdded = (
  address: Address | undefined,
  sellProperties: MuscovyPropertySchemaType[] | undefined
): boolean => {
  // Check for address already added
  const hasAddedSameProperty = sellProperties?.find((property) => {
    if (address?.full_address && property.address) {
      return address.full_address === property.address
    }
  })

  if (hasAddedSameProperty) {
    return true
  }
  return false
}

export const useDisableAddPropertyFormSubmitButton = ({
  transactionType,
  validToAddProperty,
  isFormSubmitting,
  domainPropertySuggestion,
  manualAddress,
  sellProperties,
  setDisableAddPropertyButton,
  setShowAddedSamePropertyError,
}: {
  transactionType: TransactionType
  validToAddProperty: boolean
  isFormSubmitting: boolean
  domainPropertySuggestion: DomainProperty | undefined
  manualAddress: Address | undefined
  sellProperties: MuscovyPropertySchemaType[] | undefined
  setDisableAddPropertyButton: React.Dispatch<React.SetStateAction<boolean>>
  setShowAddedSamePropertyError: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  useEffect(() => {
    if (!isFormSubmitting && transactionType === TransactionType.Buying && !validToAddProperty) {
      setDisableAddPropertyButton(true)
      setShowAddedSamePropertyError(false)
      return
    }

    if (!isFormSubmitting && transactionType === TransactionType.Selling) {
      const address = mergeAddress({
        isManual: !!manualAddress,
        manualAddress,
        domainAddress: {
          id: domainPropertySuggestion?.id,
          address: domainPropertySuggestion?.address,
          addressComponents: domainPropertySuggestion?.addressComponents,
        },
      })
      const alreadyAdded = isSellAddressAlreadyAdded(address, sellProperties)
      setDisableAddPropertyButton(alreadyAdded)
      setShowAddedSamePropertyError(alreadyAdded)
      return
    }

    setDisableAddPropertyButton(false)
    setShowAddedSamePropertyError(false)
  }, [transactionType, validToAddProperty, isFormSubmitting, domainPropertySuggestion, manualAddress, sellProperties])
}
