import { GetHomeBuyingJourneyResponse, GetHomeBuyingJourneyHbj, TransactionPhase, State } from '@home-in/models'
import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'

const getActiveTransactions = (hbjData: GetHomeBuyingJourneyResponse) =>
  hbjData?.hbj?.filter(
    (hbj) =>
      hbj.transaction_phase !== TransactionPhase.Settled &&
      hbj.transaction_phase !== TransactionPhase.ApproachingSettlement &&
      hbj.transaction_phase !== TransactionPhase.Archived &&
      hbj.transaction_phase !== TransactionPhase.NotProgressing
  )
const getSettlingTransactions = (hbjData: GetHomeBuyingJourneyResponse) =>
  hbjData?.hbj?.filter(
    (hbj) =>
      hbj.transaction_phase === TransactionPhase.Settled ||
      hbj.transaction_phase === TransactionPhase.ApproachingSettlement
  )

interface isSettledPropertyForStateProps {
  hbjData: GetHomeBuyingJourneyResponse
  propertyData: WaratahPropertyWithChecklist[]
  state: State
}

const isSettledPropertyForState = ({ hbjData, propertyData, state }: isSettledPropertyForStateProps) => {
  const settlingTransactions = getSettlingTransactions(hbjData)
  const settlingTransactionsWithPropertyInfo = createTransactionsWithPropertyInfo(settlingTransactions, propertyData)
  const settledPropertyForState = settlingTransactionsWithPropertyInfo.some(
    (transaction) => transaction.state === state && transaction.propertyCount > 0
  )
  return settledPropertyForState
}

interface GetHomeBuyingJourneyHbjWithPropertyInfo extends GetHomeBuyingJourneyHbj {
  state: State | undefined
  propertyCount: number
}

const createTransactionsWithPropertyInfo = (
  transactions: GetHomeBuyingJourneyHbj[],
  propertyData: WaratahPropertyWithChecklist[]
): GetHomeBuyingJourneyHbjWithPropertyInfo[] => {
  return transactions.map((transaction) => {
    const propertiesInTransaction = propertyData.filter(
      (property) => !property.propertyArchived && property.hbjId === transaction.hbj_id
    )
    const propertyCount = propertiesInTransaction.length
    return {
      ...transaction,
      state: propertiesInTransaction[0]?.state,
      propertyCount,
    }
  })
}

interface GetTransactionIdProps {
  transactionsWithPropertyInfo: GetHomeBuyingJourneyHbjWithPropertyInfo[]
  state: State
}

const getTransactionId = ({ transactionsWithPropertyInfo, state }: GetTransactionIdProps) => {
  const transactionsWithState = transactionsWithPropertyInfo.filter((transaction) => transaction.state === state)
  const emptyTransactions = transactionsWithPropertyInfo.filter((transaction) => transaction.propertyCount === 0)

  if (transactionsWithState.length === 1) {
    return transactionsWithState[0].hbj_id
  }

  if (transactionsWithState.length > 1) {
    // If more than one property, add it to the transaction with the most properties
    // Do not add any sorting logic based on the transaction phase
    // If your property is not approaching settlement (or beyond)
    // We want to keep adding properties to that transaction
    const transactionWithMostProperties = transactionsWithState.reduce((maxTransaction, currentTransaction) => {
      return maxTransaction.propertyCount >= currentTransaction.propertyCount ? maxTransaction : currentTransaction
    })
    return transactionWithMostProperties.hbj_id
  }

  if (emptyTransactions.length > 0) {
    return emptyTransactions[0].hbj_id
  }

  return null
}

const getAvailableStatesToAddProperty = (
  transactionsWithPropertyInfo: GetHomeBuyingJourneyHbjWithPropertyInfo[]
): string[] => {
  const uniqueStates = new Set(
    transactionsWithPropertyInfo
      .map((transaction) => transaction.state)
      .filter((state): state is State => state !== undefined)
  )
  return Array.from(uniqueStates)
}

const errorMessagesAddProperty = {
  approachingSettlementError:
    'You cannot add a property as you are approaching settlement or you have already settled on a property. To add more properties please Get in Touch with your buying assistant.',
  settledPropertyInSameStateError: (state: State) =>
    `You cannot add a property as you are approaching settlement or you have already settled on your property in ${state}. To add more properties in ${state} please Get in Touch with your buying assistant.`,
  addingPropertyInNewStateError: (state: State, statesList?: string) =>
    `You cannot add a property in ${state}${
      statesList && ` as you have already added properties in ${statesList}`
    }. If you need to add a property in ${state}, please Get in Touch with your buying assistant.`,
}

const getErrorForNoTransactionId = ({
  state,
  availableStates,
  settledPropertyForState,
}: {
  state: State
  availableStates: string[]
  settledPropertyForState: boolean
}): { errorSlug: ErrorSlug; errorMessage: string } => {
  const statesList =
    availableStates.length > 1
      ? `${availableStates.slice(0, -1).join(', ')} and ${availableStates[availableStates.length - 1]}`
      : availableStates[0]

  if (settledPropertyForState) {
    return {
      errorSlug: ErrorSlug.AllTransactionsSettled,
      errorMessage: errorMessagesAddProperty.settledPropertyInSameStateError(state),
    }
  }

  return {
    errorSlug: ErrorSlug.ExploringPropertiesInMultipleStates,
    errorMessage: errorMessagesAddProperty.addingPropertyInNewStateError(state, statesList),
  }
}

enum ErrorSlug {
  AllTransactionsSettled = 'existing-transactions-approaching-or-settled',
  ExploringPropertiesInMultipleStates = 'exploring-properties-in-multiple-states',
}

interface GetActiveTransactionForBuyPropertyProps {
  hbjData: GetHomeBuyingJourneyResponse | undefined
  propertyData: WaratahPropertyWithChecklist[] | undefined
  state: State | undefined
}

interface GetActiveTransactionForBuyPropertyOutput {
  hbjId: string | null | undefined
  waitingForData: boolean
  errorMessage: string | undefined
  errorSlug: string | undefined
}

// Each sell property has it's own transaction so it doesn't need this function
export const getActiveTransactionForBuyProperty = ({
  hbjData,
  state,
  propertyData,
}: GetActiveTransactionForBuyPropertyProps): GetActiveTransactionForBuyPropertyOutput => {
  if (!hbjData || !propertyData || !state) {
    return { hbjId: undefined, waitingForData: true, errorMessage: undefined, errorSlug: undefined }
  }
  const activeTransactions = getActiveTransactions(hbjData)

  if (activeTransactions.length === 0) {
    return {
      hbjId: null,
      waitingForData: false,
      errorSlug: ErrorSlug.AllTransactionsSettled,
      errorMessage: errorMessagesAddProperty.approachingSettlementError,
    }
  }

  const transactionsWithPropertyInfo = createTransactionsWithPropertyInfo(activeTransactions, propertyData)
  const transactionId = getTransactionId({ transactionsWithPropertyInfo, state })

  if (!transactionId) {
    const availableStates = getAvailableStatesToAddProperty(transactionsWithPropertyInfo)
    const settledPropertyForState = isSettledPropertyForState({ hbjData, propertyData, state })

    return {
      hbjId: null,
      waitingForData: false,
      ...getErrorForNoTransactionId({ availableStates, state, settledPropertyForState }),
    }
  }

  return { hbjId: transactionId, waitingForData: false, errorMessage: undefined, errorSlug: undefined }
}
