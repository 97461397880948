import React, { HTMLAttributes } from 'react'
import { Card } from '@elements/card'
import Illustration, { Illustrations } from '@elements/icons/illustration'

interface LinkCardProps {
  icon: Illustrations
  message: string
  title: string
  buttonText: string
  action: () => void
  testId?: string
}

export const LinkCard = (props: LinkCardProps) => {
  return (
    <button
      data-testid={props.testId}
      className="mb-2 w-full cursor-pointer appearance-none border-none bg-transparent text-left md:mb-4"
      onClick={props.action}
    >
      <Card className="flex p-3 duration-200 hover:shadow-md active:shadow-sm md:p-5">
        <Illustration className="mr-4 max-w-[24px] shrink-0 self-start md:max-w-full" asset={props.icon} />
        <div>
          <div className="m-0 p-0 text-sm font-bold leading-normal md:text-base">{props.title}</div>
          <div className="m-0 p-0 text-sm leading-normal md:text-sm">{props.message}</div>
          <div className="mt-2 text-sm text-secondary md:mt-4 md:text-base">{props.buttonText}</div>
        </div>
      </Card>
    </button>
  )
}
