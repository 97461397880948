import { useRouter } from 'next/router'
import React from 'react'
import { LoadingWrapper } from '@elements/status-handlers/loading-wrapper'
import { Tab, TabContent, TabItem, TabList, TabPane } from '@elements/tabs'
import { Activity } from '@features/home/parts/activity'
import { Documents } from '@features/home/parts/documents'
import { Team } from '@features/home/parts/team'
import { useGetHomeBuyingJourneyQuery } from '@redux/apis/home'

export const Home = ({ openTab }: { openTab: number }) => {
  const { isLoading, isError } = useGetHomeBuyingJourneyQuery()
  const router = useRouter()

  return (
    <LoadingWrapper isLoading={isLoading} isError={isError}>
      <Tab>
        <TabList className="z-40 bg-light/80 backdrop-blur-lg backdrop-saturate-150">
          <TabItem active={openTab === 1} numTabs={3} href="/" onClick={() => router.push('/')}>
            Activity
          </TabItem>
          <TabItem active={openTab === 2} numTabs={3} href="/team" onClick={() => router.push('/team')}>
            Team
          </TabItem>
          <TabItem active={openTab === 3} numTabs={3} href="/documents" onClick={() => router.push('/documents')}>
            Documents
          </TabItem>
        </TabList>

        <TabContent>
          <TabPane active={true}>
            {openTab === 1 && <Activity />}
            {openTab === 2 && <Team />}
            {openTab === 3 && <Documents />}
          </TabPane>
        </TabContent>
      </Tab>
    </LoadingWrapper>
  )
}
