import { useEffect, useState } from 'react'
import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'
import { useGetHomeBuyingJourneyQuery } from '@redux/apis/home'
import { useGetAllSellChecklistsQuery } from '@redux/apis/sell-checklist'
import { useGetSellPropertiesQuery } from '@redux/apis/sell-property'
import { useAppSelector } from '@redux/hooks'
import { getUnarchivedBuyProperties, getUnarchivedSellProperties } from '@utils/helpers/getUnarchivedProperties.helper'
import {
  mergeHbjAndChecklist,
  MergedHbjAndChecklist,
  mergeSellPropertiesAndChecklists,
  MergedSellPropertyAndChecklist,
} from '@utils/helpers/properties.helpers'

export type CombinedPropertiesList = (MergedHbjAndChecklist | MergedSellPropertyAndChecklist)[]

interface PropertiesHook {
  data: CombinedPropertiesList
  dataWithoutArchivedProperties: CombinedPropertiesList
  unarchivedSellProperties: MergedSellPropertyAndChecklist[]
  unarchivedBuyProperties: MergedHbjAndChecklist[]
  loading: boolean
  isSuccess: boolean
  isError: boolean
}

export const useProperties = (): PropertiesHook => {
  const [properties, setProperties] = useState<CombinedPropertiesList>([])
  const [unarchivedBuyProperties, setUnarchivedBuyProperties] = useState<MergedHbjAndChecklist[]>([])
  const [unarchivedSellProperties, setUnarchivedSellProperties] = useState<MergedSellPropertyAndChecklist[]>([])
  const removedBuyChecklists = useAppSelector<WaratahPropertyWithChecklist[]>(
    (state) => state.checklist.removedChecklists
  )
  const removedSellProperties = useAppSelector((state) => state.sell.removedSellProperties)

  // Step 1: Fetch HBJ data
  const {
    data: hbjData,
    isError: hbjIsError,
    isSuccess: hbjIsSuccess,
    isFetching: hbjIsFetching,
    isUninitialized: hbjIsUninitialized,
  } = useGetHomeBuyingJourneyQuery()

  // Step 2: Fetch buying checklist data
  const checklistData = useAppSelector((state) => state.checklist.checklists)

  // Step 3: Fetch sell properties data
  const {
    data: sellPropertiesData,
    isError: sellPropertiesIsError,
    isSuccess: sellPropertiesSuccess,
    isFetching: sellPropertiesIsFetching,
    isUninitialized: sellPropertiesIsUninitialized,
  } = useGetSellPropertiesQuery()

  // Step 4 fetch sell checklist data
  const {
    data: sellChecklistsData,
    isError: sellChecklistsIsError,
    isSuccess: sellChecklistsSuccess,
    isFetching: sellChecklistsIsFetching,
    isUninitialized: sellChecklistsIsUninitialized,
  } = useGetAllSellChecklistsQuery()

  // Step 5 build properties
  useEffect(() => {
    if (hbjIsSuccess && sellPropertiesSuccess && sellChecklistsSuccess) {
      // Merge HBJ data with buying checklist data
      const buyProperties = mergeHbjAndChecklist(hbjData, checklistData || [])
      const sellProperties = mergeSellPropertiesAndChecklists(sellPropertiesData, sellChecklistsData)

      setProperties([...buyProperties, ...sellProperties])
      setUnarchivedBuyProperties(getUnarchivedBuyProperties({ buyProperties, removedBuyChecklists }))
      setUnarchivedSellProperties(getUnarchivedSellProperties({ sellProperties, removedSellProperties }))
    }
  }, [
    hbjData,
    checklistData,
    sellPropertiesData,
    sellChecklistsData,
    hbjIsSuccess,
    sellPropertiesSuccess,
    sellChecklistsSuccess,
    removedBuyChecklists,
    removedSellProperties,
  ])

  const isUninitialized = hbjIsUninitialized || sellPropertiesIsUninitialized || sellChecklistsIsUninitialized
  const isError = hbjIsError || sellPropertiesIsError || sellChecklistsIsError
  const isFetching = hbjIsFetching || sellPropertiesIsFetching || sellChecklistsIsFetching
  const isSuccess = hbjIsSuccess && sellPropertiesSuccess && sellChecklistsSuccess

  return {
    data: properties,
    dataWithoutArchivedProperties: [...unarchivedBuyProperties, ...unarchivedSellProperties],
    unarchivedSellProperties,
    unarchivedBuyProperties,
    loading: isError ? false : isFetching || isUninitialized,
    isSuccess,
    isError,
  }
}
