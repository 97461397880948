import { DomainPropertyResponse } from '@home-in/models'
import { useEffect } from 'react'
import { useLazyGetListingQuery } from '@redux/apis/domain'

// You should use `useGetDomainPropertyData` to get the `domainProperty`
export const useGetDomainListingData = (domainProperty: DomainPropertyResponse | null | undefined) => {
  // Get listing info from Domain.com.au API
  const [getListing, { data: domainListingData, isLoading }] = useLazyGetListingQuery()
  useEffect(() => {
    if (!domainProperty) return
    if (Array.isArray(domainProperty.adverts) && domainProperty.adverts?.[0]?.advertId) {
      getListing(domainProperty.adverts[0].advertId)
    }
  }, [domainProperty])

  return { domainListingData, isLoading }
}
