import { useAppSelector } from '@redux/hooks'

// Function to parse a date string in the format "DD/MM/YYYY" and return a Date object
const parseDate = (dateString: string): Date | undefined => {
  if (!dateString) return undefined
  const [day, month, year] = dateString.split('/').map(Number)
  return new Date(year, month - 1, day)
}

const useCBAPreApprovalHBJ = () => {
  const allHbjs = useAppSelector((state) => state.home.hbj)
  const hbjsWithLoanAmounts = allHbjs.filter((hbj) => !!hbj.loan.loanAmount)

  if (hbjsWithLoanAmounts.length === 0) {
    return undefined
  }

  const hbjWithLatestExpiry = hbjsWithLoanAmounts.reduce((latest, current) => {
    const latestExpiry = parseDate(latest.loan.expiryDate)
    const currentExpiry = parseDate(current.loan.expiryDate)

    if (!latestExpiry) return current
    if (!currentExpiry) return latest

    return currentExpiry > latestExpiry ? current : latest
  }, hbjsWithLoanAmounts[0])

  return hbjWithLatestExpiry
}

export default useCBAPreApprovalHBJ
