import { SliceProperty, StateType, TransactionType } from '@home-in/models'
import React from 'react'
import { useSellUiChanges } from '@hooks/useSellUIChanges'
import mock_property from '@public/mock/checklist-property.png'
import { useRemovePropertyMutation } from '@redux/apis/checklist'
import { useArchiveSellPropertyMutation } from '@redux/apis/sell-property'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { disableModal, enableModal, setModalContent } from '@redux/reducers/modal'
import { fireToastNotifications } from '@redux/reducers/notifications'
import { AnalyticsCategories, ButtonClickEventNames } from '@utils/helpers/analytics.enum'
import { PropertyCardType, PropertyCardUIWrapper } from './property-card-ui'
import { PropertyModalContent } from './property-card-ui-archive'
import { getPropertySubtitle } from './property-card.helpers'

export const PropertyCard = ({ hbjId, property, propertyId }: SliceProperty) => {
  const [removeProperty] = useRemovePropertyMutation()
  const dispatch = useAppDispatch()
  const uiChangesFromSell = useSellUiChanges()
  const sellChecklists = useAppSelector((state) => state.sell.sellProperties)
  const imgSource = property.imageUrl ? `${property.imageUrl}/800x800` : mock_property

  const cardSubtitle = getPropertySubtitle({
    auctionDate: property.auctionDate,
    saleType: property.saleType,
    settlementDate: property.settlementDate,
  })

  const handleCancelModal = () => {
    dispatch(setModalContent(null))
    dispatch(disableModal())
  }
  const handleRemoveProperty = async () => {
    dispatch(setModalContent(null))
    dispatch(disableModal())
    const result = removeProperty({ hbj: hbjId, propertyId })
    dispatch(fireToastNotifications({ result, optimisticUIUpdate: true, successMessage: 'Removed property' }))
  }

  const handleRemoveClick = () => {
    dispatch(
      trackButtonClick({
        action: ButtonClickEventNames.RemovePropertyIcon,
        category: AnalyticsCategories.Property,
      })
    )

    dispatch(
      setModalContent(
        <PropertyModalContent
          removable={property?.removable || false}
          removeProperty={handleRemoveProperty}
          cancel={handleCancelModal}
          transactionType={TransactionType.Buying}
        />
      )
    )
    dispatch(enableModal())
  }

  return (
    <PropertyCardUIWrapper
      link={`/checklist/${hbjId}/${propertyId}`}
      handleRemoveClick={handleRemoveClick}
      propertyAddress={property.address}
      propertyType={PropertyCardType.buy}
      imgSource={imgSource}
      uiChangesFromSell={uiChangesFromSell}
      sellChecklists={sellChecklists}
      cardSubtitle={cardSubtitle}
    />
  )
}

// TODO declare this in Platypus when we have the final data design for sell property
interface SellPropertyCardInterface {
  propertyId: string
  property: {
    addressKey: string
    address: string
    state: StateType
    imageUrl: string | undefined
  }
  removable: boolean
}

export const SellPropertyCard = ({ property, propertyId, removable }: SellPropertyCardInterface) => {
  const [archiveSellProperty] = useArchiveSellPropertyMutation()
  const uiChangesFromSell = useSellUiChanges()
  const dispatch = useAppDispatch()
  const sellChecklists = useAppSelector((state) => state.sell.sellProperties)
  const imgSource = property?.imageUrl || mock_property

  const handleCancelModal = () => {
    dispatch(setModalContent(null))
    dispatch(disableModal())
  }
  const handleRemoveProperty = async () => {
    dispatch(setModalContent(null))
    dispatch(disableModal())
    const result = archiveSellProperty({ propertyId })
    dispatch(fireToastNotifications({ result, optimisticUIUpdate: true, successMessage: 'Removed property' }))
  }

  const handleRemoveClick = () => {
    dispatch(
      trackButtonClick({
        action: ButtonClickEventNames.RemovePropertyIcon,
        category: AnalyticsCategories.Property,
      })
    )

    dispatch(
      setModalContent(
        <PropertyModalContent
          removable={removable}
          removeProperty={handleRemoveProperty}
          cancel={handleCancelModal}
          transactionType={TransactionType.Selling}
        />
      )
    )
    dispatch(enableModal())
  }

  return (
    <PropertyCardUIWrapper
      link={`sell/checklist/${propertyId}`}
      handleRemoveClick={handleRemoveClick}
      propertyAddress={property.address}
      propertyType={PropertyCardType.sell}
      imgSource={imgSource}
      uiChangesFromSell={uiChangesFromSell}
      sellChecklists={sellChecklists}
    />
  )
}
