import { GetHomeBuyingJourneyResponse, GetHomeBuyingJourneyHbj, MuscovyPropertySchemaType } from '@home-in/models'
import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'
import { SellChecklist } from '@redux/reducers/sell'

export interface MergedHbjAndChecklist {
  propertyChecklistInfo: WaratahPropertyWithChecklist
  transactionInfo: GetHomeBuyingJourneyHbj | undefined
}
export interface MergedSellPropertyAndChecklist {
  propertyInfo: MuscovyPropertySchemaType
  checklistInfo: SellChecklist | undefined
}

export const isMergedHbjAndChecklist = (property: MergedHbjAndChecklist | MergedSellPropertyAndChecklist): boolean => {
  return 'propertyChecklistInfo' in property && 'transactionInfo' in property
}

export const isMergedSellPropertyAndChecklist = (
  property: MergedHbjAndChecklist | MergedSellPropertyAndChecklist
): boolean => {
  return 'checklistInfo' in property && 'propertyInfo' in property
}

export const mergeHbjAndChecklist = (
  hbjData: GetHomeBuyingJourneyResponse,
  checklistData: WaratahPropertyWithChecklist[]
): MergedHbjAndChecklist[] => {
  return checklistData.map((checklist) => {
    const hbjOfChecklist = hbjData?.hbj?.find((hbj) => checklist.hbjId === hbj.hbj_id)
    return {
      propertyChecklistInfo: checklist,
      transactionInfo: hbjOfChecklist,
    }
  })
}

export const mergeSellPropertiesAndChecklists = (
  sellPropertiesData: MuscovyPropertySchemaType[],
  sellChecklistsData: SellChecklist[]
): MergedSellPropertyAndChecklist[] => {
  return sellPropertiesData.map((sellProperty) => {
    const checklistInfo = sellChecklistsData?.find((checklist) => checklist.propertyId === sellProperty.propertyId)
    return {
      checklistInfo,
      propertyInfo: sellProperty,
    }
  })
}
