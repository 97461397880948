import { Address, DomainPropertySuggestionResponse } from '@home-in/models'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'
import PropertySearchBar from '@elements/property-search/property-search-bar'
import { useLazySuggestPropertiesQuery } from '@redux/apis/domain'
import { AddPropertyDialog } from './add-property-dialog'
import { ManualAddPropertyDialog } from './manual-add-property-dialog'

export default function PropertySearch() {
  const [searchText, setSearchText] = useState('')
  const [selectedOption, setSelectedOption] = useState<DomainPropertySuggestionResponse[0] | null>(null)
  const [fetchSuggestProperties, { data }] = useLazySuggestPropertiesQuery()

  const doPropertySearch = useDebouncedCallback(async (address: string): Promise<void> => {
    await fetchSuggestProperties({ terms: address, time: new Date().toString() })
  }, 300)

  useEffect(() => {
    doPropertySearch(searchText)
  }, [searchText])

  // Add property dialog
  const [openAddPropertyDialog, setAddPropertyDialogOpen] = useState(false)
  const handleOpenAddPropertyDialog = () => {
    setAddPropertyDialogOpen(true)
  }
  const handleCloseAddPropertyDialog = () => {
    setAddPropertyDialogOpen(false)
  }

  // Manual address dialog
  const formMethods = useForm<Address>()
  const [openManualAddressDialog, setManualAddressDialogOpen] = useState(false)
  const handleOpenManualAddressDialog = () => {
    setManualAddressDialogOpen(true)
  }

  const handleCloseManualAddressDialog = () => {
    setManualAddressDialogOpen(false)
  }

  return (
    <div>
      <PropertySearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        addressOptions={data || []}
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        handleOpenAddPropertyDialog={handleOpenAddPropertyDialog}
        handleOpenManualAddressDialog={handleOpenManualAddressDialog}
      />
      {openAddPropertyDialog && (
        <AddPropertyDialog open={openAddPropertyDialog} handleClose={handleCloseAddPropertyDialog} />
      )}
      {openManualAddressDialog && (
        <ManualAddPropertyDialog
          open={openManualAddressDialog}
          handleClose={handleCloseManualAddressDialog}
          handleOpenAddPropertyDialog={handleOpenAddPropertyDialog}
          formMethods={formMethods}
        />
      )}
    </div>
  )
}
