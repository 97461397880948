import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { FC, HTMLAttributes } from 'react'
import { Illustrations } from '@elements/icons/illustration'
import { LinkCard } from '@features/home/components/link-card'
import { dispatchDataToMammoth, isAppOnMobilePlatform } from '@helpers/app-data-bridge'
import { useGetHBJsByTask } from '@hooks/useGetHBJsByTask'
import { useAppDispatch } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { toastNotifySuccess } from '@utils/helpers/reduxAction.helpers'

interface Props extends HTMLAttributes<HTMLDivElement> {}

interface Button {
  title: string
  action: () => void
}

const title = 'Loving the app?'
const message =
  "If you've been enjoying the Home-in experience, share with your friends to simplify their next home buying journey"
const shareLink = 'https://www.home-in.com.au/landing/refer-a-friend'

export const SharingBanner: FC<Props> = () => {
  const { shareWithFriendsTile, forceDisplayShareWithFriendsTile } = useFlags()
  const settledProperties = useGetHBJsByTask({ taskRef: 'ST10', completed: true })
  const dispatch = useAppDispatch()

  const mobileButton: Button = {
    title: 'Share with friends',
    action: displaySharePanel,
  }

  const desktopButton: Button = {
    title: 'Copy link',
    action: copyToClipboard,
  }

  function displaySharePanel() {
    dispatch(
      trackButtonClick({
        action: 'Share With Friends',
        category: AnalyticsCategories.ShareHomeIn,
      })
    )

    dispatchDataToMammoth('promptNativeShare')
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(shareLink)

    dispatch(
      trackButtonClick({
        action: 'Copy Referral Link',
        category: AnalyticsCategories.ShareHomeIn,
      })
    )

    dispatch(toastNotifySuccess('Copied'))
  }

  const button = isAppOnMobilePlatform() ? mobileButton : desktopButton

  const isVisible =
    forceDisplayShareWithFriendsTile || (shareWithFriendsTile && settledProperties && !!settledProperties.length)

  if (!isVisible) return null

  return (
    <LinkCard
      testId="sharing-banner"
      icon={Illustrations.friends}
      title={title}
      message={message}
      action={button.action}
      buttonText={button.title}
    />
  )
}
