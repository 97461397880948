import router from 'next/router'
import { Illustrations } from '@elements/icons/illustration'
import { HomeState } from '@redux/reducers/home'

export const getLenderTileDetails = (home: HomeState) => {
  const illustration = Illustrations.homeDollar
  let title: string = ''
  let message: string = ''
  const callback = () => router.push('/add-lender')
  let active = false

  const dummyLender = 'dummylender@cba.com.au'
  const hasChl = !!home.activeHbj?.loan?.chl?.length
  const hasLender =
    !!home.activeHbj?.loan?.lenderEmail?.length && home.activeHbj?.loan?.lenderEmail.toLowerCase() !== dummyLender
  const hasCbaLender = hasLender && !!home.activeHbj?.loan?.lenderEmail?.toLowerCase().endsWith('@cba.com.au')

  if (!hasLender) {
    active = true
    title = 'Using your own lender?'
    message =
      'You can add your lender details to help them receive important updates from Home-in and get you settlement ready'
  }

  if (hasCbaLender && !hasChl) {
    active = true
    title = 'Add your CHL number'
    message =
      'Add your home loan application number so that your lender can receive updates from Home-in and get you settlement ready'
  }

  return {
    illustration,
    title,
    message,
    active,
    callback,
  }
}
