import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { HTMLAttributes } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@elements/button'
import { Card } from '@elements/card'
import Illustration, { Illustrations } from '@elements/icons/illustration'
import { useMobileQuery } from '@hooks/layoutHooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { classNames } from '@utils/helpers/classNameHelper'

interface IAddPropertyBanner extends HTMLAttributes<HTMLDivElement> {
  heading?: string
  content?: string
  callback?: () => void
}

export const AddPropertyBanner = ({
  className,
  heading = 'Buying a new home?',
  content = 'Unlock your personalised home buying checklist, access quality conveyancing, get a contract reviewed and more!',
  callback,
}: IAddPropertyBanner) => {
  const isMobile = useMobileQuery()
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(
      trackButtonClick({
        action: 'Add property home screen',
        category: AnalyticsCategories.WelcomeScreens,
      })
    )
    if (callback) callback()
  }

  const { enhanceAddProperty } = useFlags()

  return (
    <Card className={classNames('flex flex-col items-center', className)}>
      <div className="relative mb-6 h-[185px] w-[185px] rounded-full bg-light">
        <Illustration asset={Illustrations.homeAddProperty} size={175} className="absolute left-2 top-0" />
      </div>
      <div className="flex max-w-3xl flex-col justify-center">
        <span className="mb-6 text-center text-xl font-bold">{heading}</span>
        <span className="mb-6 text-center text-base font-normal">{content}</span>
      </div>

      {!enhanceAddProperty && (
        <Button callback={onClick} size={isMobile ? 'full' : 'lg'} className="mt-8 font-normal">
          Add a property
        </Button>
      )}
    </Card>
  )
}
