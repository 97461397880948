import Link from 'next/link'
import React, { HTMLAttributes, MouseEventHandler } from 'react'
import { classNames } from '@utils/helpers/classNameHelper'

interface TabItemProps extends HTMLAttributes<HTMLAnchorElement> {
  active: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
  numTabs: number
  href?: string
}

interface TabPaneProps extends HTMLAttributes<HTMLDivElement> {
  active: boolean
}

export const Tab = ({ children, className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames('relative flex flex-col items-center justify-center w-full md:p-4 h-full', className)}>
      {children}
    </div>
  )
}

export const TabContent = ({ children, className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className="relative flex w-full min-w-0 flex-auto flex-col break-words pb-4">
      <div className={classNames('flex-auto font-light leading-normal', className)}>{children}</div>
    </div>
  )
}

export const TabItem = ({ children, active, onClick, href, className, numTabs }: TabItemProps) => {
  return (
    <li
      className={classNames(
        'relative text-center duration-500 hover:bg-background transition-all rounded-t-lg',
        `w-1/${numTabs}`
      )}
    >
      <div
        className={`absolute bottom-0 h-1 w-full origin-bottom transition-all duration-300 ${
          active ? 'animate-grow-y bg-primary' : 'scale-y-[0.25] bg-background-dark-100'
        }`}
      ></div>
      {onClick ? (
        <a
          onClick={(e) => {
            e.preventDefault()
            onClick(e)
          }}
          className={classNames(
            'flex items-center justify-center transition-colors duration-500 py-4 px-6',
            active ? 'font-bold' : 'border-transparent',
            className
          )}
          role="tablist"
          href={href}
        >
          {children}
        </a>
      ) : (
        href && (
          <Link href={href}>
            <a
              className={classNames(
                'flex items-center justify-center transition-colors duration-500 py-4 px-6 border-b-3',
                active ? 'font-bold border-primary' : 'border-transparent',
                className
              )}
              role="tablist"
            >
              {children}
            </a>
          </Link>
        )
      )}
    </li>
  )
}

export const TabList = ({ children, className }: HTMLAttributes<HTMLUListElement>) => {
  return (
    <ul
      className={classNames(
        'flex flex-wrap justify-center mb-4 list-none z-10 w-break-margin md:w-full sticky top-0 md:md:top-16',
        className
      )}
      role="tablist"
    >
      {children}
    </ul>
  )
}

export const TabPane = ({ children, active, className }: TabPaneProps) => {
  return <div className={classNames(active ? 'block' : 'hidden', 'h-full', className)}>{children}</div>
}
