import { EmojiFeedback } from '@home-in/models'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@elements/button'
import { Card } from '@elements/card'
import { TextAreaField } from '@elements/forms'
import Illustration, { Illustrations } from '@elements/icons/illustration'
import { Modal } from '@elements/modal'
import { useMobileQuery } from '@hooks/layoutHooks'
import { useSendEmojiFeedbackMutation } from '@redux/apis/profile'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { setEmojiFeedback, useEmojiFeedbackSelector } from '@redux/reducers/feedback'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { AnalyticsEvent } from '@utils/helpers/analytics.interface'
import { classNames } from '@utils/helpers/classNameHelper'
import { dispatchDataToMammoth, isAppOnMobilePlatform } from '../../helpers/app-data-bridge'

export const EmojiFeedbackBanner = () => {
  const emojiFeedback = useEmojiFeedbackSelector()
  const auth0id = useAppSelector((state) => state.auth.user?.sub) || ''
  const dispatch = useAppDispatch()
  const isMobile = useMobileQuery()
  const [sendEmojiFeedbackApi] = useSendEmojiFeedbackMutation()
  const [showModal, setShowModal] = useState(false)
  const emojis = ['Sad', 'Happy'] as const

  type emojiKeyType = (typeof emojis)[number]

  const methods = useForm<{ userFeedback: string }>({
    defaultValues: { userFeedback: '' },
  })
  const feedbackInput = methods.watch('userFeedback')

  const handleClick = (emoji: emojiKeyType) => {
    dispatch(setEmojiFeedback({ emoji, auth0id }))
    if (emoji === 'Happy') {
      dispatchDataToMammoth('promptNativeReview')
      sendFeedback(emoji)
      if (!isAppOnMobilePlatform()) setShowModal(true)
    } else setShowModal(true)
  }

  const dismissCall = () => {
    setShowModal(false)
    const userFeedback = methods.getValues('userFeedback')
    methods.setValue('userFeedback', '')
    sendFeedback(emojiFeedback as emojiKeyType, userFeedback)
  }

  const sendFeedback = (emoji: emojiKeyType, verbatim?: string) => {
    const analyticsEventBody: AnalyticsEvent = {
      action: `Emoji feedback ${emoji}`,
      category: AnalyticsCategories.FeedbackEmoji,
      value: verbatim,
    }

    dispatch(trackButtonClick(analyticsEventBody))

    sendEmojiFeedbackApi({ emoji: EmojiFeedback[emoji], verbatim })
  }

  return (
    <>
      <Card className="mb-2 columns-1 p-2 md:mb-4 md:p-2">
        <div className="mt-1 mb-3 flex basis-full justify-center">
          <span>Rate your experience</span>
        </div>
        <div className="m-2 flex basis-full justify-center">
          {emojis.map((emoji) => (
            <button
              disabled={emojiFeedback === emoji}
              onClick={() => handleClick(emoji)}
              key={emoji}
              className="mr-6 ml-6"
            >
              <Illustration
                asset={emojiFeedback === emoji ? Illustrations[`emoji${emoji}`] : Illustrations[`emoji${emoji}Gray`]}
                size={40}
              ></Illustration>
            </button>
          ))}
        </div>
      </Card>
      <Modal
        size={isMobile ? 'medium' : 'small'}
        active={showModal}
        variant={isMobile ? 'full-screen' : 'normal'}
        toggler={dismissCall}
        modalTitle={
          emojiFeedback === 'Happy'
            ? 'Enjoying Home-in?'
            : emojiFeedback === 'Sad'
            ? 'We’re sorry to hear that :('
            : 'How can we do better?'
        }
        quickDismiss={true}
      >
        {emojiFeedback === 'Happy' ? (
          <>
            <p className="mt-6 mb-5 text-center text-muted">
              Recommend us to other home buyers by leaving us a rating or a review.
            </p>
            <div className="flex basis-full columns-1 justify-center">
              <Button
                className={classNames({ 'basis-full': isMobile })}
                type="button"
                link="https://g.page/r/CRBdpyTgPTeFEB0/review"
              >
                Write a review
              </Button>
            </div>
          </>
        ) : (
          <>
            {emojiFeedback === 'Sad' && (
              <p className="mt-6 mb-5 text-center text-muted">
                What can we do to improve your experience and help you in your home buying journey?
              </p>
            )}

            <div className="pb-4">
              <TextAreaField
                name="userFeedback"
                label="What went wrong?"
                className="mt-2 mb-0 md:max-w-lg"
                required={true}
                hasRequiredLabel={false}
                control={methods.control}
                maxLength={255}
                rows={5}
              />
              <div className="-mt-6">
                <span className="text-sm text-muted">{255 - (feedbackInput ? feedbackInput.length : 0)}</span>
              </div>
            </div>
            <div className="flex basis-full columns-1 justify-center">
              <Button
                className={classNames({ 'basis-full': isMobile })}
                type="submit"
                callback={methods.handleSubmit(dismissCall)}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
