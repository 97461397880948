import {
  Address,
  DomainListingResponse,
  DomainProperty,
  DomainPropertyResponse,
  PropertySaleType,
  PropertyType,
  State,
  SupportedPropertyType,
  TransactionType,
  YesNo,
} from '@home-in/models'
import { MonetizationOnRounded, SellRounded } from '@mui/icons-material'
import { DialogContent } from '@mui/material'
import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useIntercom } from 'react-use-intercom'
import { ErrorAlert } from '@elements/error-alert'
import { DateTimeField, Listbox, RadioCards } from '@elements/forms'
import { LabelRequired } from '@elements/forms/label-required'
import { useFireMixpanelErrorForNoHbjId, useGetHbjIdToAddProperty } from '@hooks/useGetHbjIdToAddProperty'
import { useSellProperties } from '@hooks/useSellProperties'
import { useAddPropertyMutation } from '@redux/apis/checklist'
import { useAddSellChecklistMutation } from '@redux/apis/sell-checklist'
import { useAddSellPropertyMutation } from '@redux/apis/sell-property'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { sevenDaysAgo } from '@utils/helpers/date.helpers'
import { enumToSelectOptions, filterEnumToRadioCardInputs } from '@utils/helpers/enum.helpers'

import {
  useDisableAddPropertyFormSubmitButton,
  usePrefillFormWithDomainListingData,
  usePrefillPropertyTypeWithDomainPropertyData,
  useResetAuctionDate,
} from './form-helpers'
import { submitAddPropertyForm } from './submit-add-property-form'
interface AddPropertyDialogFormProps {
  domainPropertySuggestion: DomainProperty | undefined
  domainProperty: DomainPropertyResponse | undefined
  domainListing: DomainListingResponse | undefined
  manualAddress?: Address
  handleClose: () => void
  setIsFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  isFormSubmitting: boolean
  setDisableAddPropertyButton: React.Dispatch<React.SetStateAction<boolean>>
}

export interface AddPropertyDialogFormFields {
  transactionType: TransactionType
  auctionDate?: Date | string
  contractOfSale?: string
  saleType?: PropertySaleType
  propertyType?: PropertyType
  hasCosReady?: YesNo
}

export const AddPropertyDialogForm: FC<AddPropertyDialogFormProps> = ({
  domainPropertySuggestion,
  domainProperty,
  manualAddress,
  domainListing,
  handleClose,
  setIsFormSubmitting,
  isFormSubmitting,
  setDisableAddPropertyButton,
}) => {
  const methods = useForm<AddPropertyDialogFormFields>({
    defaultValues: {
      transactionType: TransactionType.Buying,
    },
  })
  const { control, handleSubmit, watch } = methods
  const saleType = watch('saleType')
  const transactionType = watch('transactionType')
  useResetAuctionDate(methods, saleType)
  usePrefillFormWithDomainListingData(methods, domainListing)
  usePrefillPropertyTypeWithDomainPropertyData(methods, domainProperty)

  const dispatch = useAppDispatch()
  const router = useRouter()
  const { show: showIntercom } = useIntercom()

  // For adding sell property
  const sellProperties = useSellProperties()
  const [addSellProperty] = useAddSellPropertyMutation()
  const [addSellChecklist] = useAddSellChecklistMutation()
  const intercom = useIntercom()

  const [showAddedSamePropertyError, setShowAddedSamePropertyError] = useState<boolean>(false)

  // For adding buy property
  const hbj = useAppSelector((state) => state.home.hbj)
  const propertyData = useAppSelector((state) => state.checklist.checklists) || []
  const [addBuyProperty] = useAddPropertyMutation()
  const { hbjIdToAddProperty, validToAddProperty, errorSlugToAddProperty, errorMessageToAddProperty } =
    useGetHbjIdToAddProperty({
      address: domainPropertySuggestion,
      hbj,
      manual_address: manualAddress,
      propertyData,
    })
  useFireMixpanelErrorForNoHbjId({
    address: domainPropertySuggestion,
    dispatch,
    errorSlugToAddProperty,
  })

  // Disable Get Started (submit) button if there's an error
  useDisableAddPropertyFormSubmitButton({
    transactionType,
    validToAddProperty,
    isFormSubmitting,
    domainPropertySuggestion,
    manualAddress,
    sellProperties,
    setDisableAddPropertyButton,
    setShowAddedSamePropertyError,
  })

  const onSubmit: SubmitHandler<AddPropertyDialogFormFields> = async (form) => {
    // We don't need to setIsFormSubmitting(false) since router takes customer to
    // checklists once a property is added successfully and this state is no longer relevant
    // (Parent dialog would be dismissed during redirect)
    setIsFormSubmitting(true)
    await submitAddPropertyForm({
      form,
      domainPropertySuggestion,
      domainProperty,
      manualAddress,
      dispatch,
      hbjIdToAddProperty,
      addBuyProperty,
      addSellProperty,
      addSellChecklist,
      router,
      intercom,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0" id="add-property-form">
        <DialogContent className="mb-16 sm:mb-8">
          <RadioCards
            control={control}
            label="Are you buying or selling this property?"
            labelClassName={'text-base sm:text-lg'}
            name="transactionType"
            inputs={[
              { input: TransactionType.Buying, icon: MonetizationOnRounded },
              { input: TransactionType.Selling, icon: SellRounded },
            ]}
            disabled={false}
            className="my-0 py-0"
          />
          {transactionType === TransactionType.Selling && showAddedSamePropertyError && (
            <div className="mt-8">
              <ErrorAlert
                heading="Oops! It looks like you've already added this property"
                message="If we've got this wrong and you need assistance, please contact our support team. Otherwise, feel
                  free to go back and view your existing property."
                buttonCallback={handleClose}
                buttonText="Go Back"
              />
            </div>
          )}
          {transactionType === TransactionType.Buying && (
            <>
              {validToAddProperty ? (
                <>
                  <p className="mb-1 mt-4 text-base sm:text-lg">
                    <LabelRequired>What is the property type?</LabelRequired>
                  </p>
                  <Listbox
                    label="Property type"
                    control={control}
                    values={enumToSelectOptions(PropertyType)}
                    defaultEmpty
                    name="propertyType"
                    className="my-0 py-0"
                    floatingLabel={true}
                    disabled={false}
                    rules={{
                      validate: {
                        supportedProperty: (type) =>
                          SupportedPropertyType.includes(type) ||
                          `Sorry, at this time we don't offer conveyancing for ${type} type properties. Please contact support@home-in.com.au for assistance.`,
                      },
                    }}
                  />
                  <RadioCards
                    control={control}
                    label="What is the sale type?"
                    labelClassName={'text-base sm:text-lg mt-4'}
                    name="saleType"
                    inputs={filterEnumToRadioCardInputs(PropertySaleType)}
                    disabled={false}
                    className="my-0 py-0"
                  />
                  {saleType === PropertySaleType.Auction && (
                    <DateTimeField
                      label="Auction date"
                      min={sevenDaysAgo()}
                      labelClassName={'text-sm sm:text-lg'}
                      name="auctionDate"
                      required
                      shrinkIfNoError
                      floatingLabel={false}
                      control={control}
                      className="my-0 py-0"
                    />
                  )}
                  <RadioCards
                    control={control}
                    label="Have you and the Seller signed the contract of sale?"
                    name="contractOfSale"
                    labelClassName={'text-base sm:text-lg mt-4'}
                    disabled={false}
                    inputs={filterEnumToRadioCardInputs(YesNo)}
                    description={
                      domainProperty?.state === State.VIC
                        ? 'Choose yes, if you can provide us with a copy of your contract signed and dated by all purchaser(s) and seller(s)'
                        : 'Choose yes, if you can provide us with a copy of your contract signed and dated by the seller(s)'
                    }
                    className="my-0 py-0"
                  />
                </>
              ) : (
                <div className="mt-4">
                  <ErrorAlert
                    heading="Please contact your buying assistant to add this property"
                    message={errorMessageToAddProperty}
                    buttonCallback={showIntercom}
                    buttonText="Get in Touch"
                  />
                </div>
              )}
            </>
          )}
        </DialogContent>
      </form>
    </>
  )
}
