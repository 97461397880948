import { PropertySaleType, typeOfSale } from '@home-in/models'
import { formatDate } from '@utils/helpers/date.helpers'

interface GetPropertySubtitle {
  saleType?: typeOfSale | PropertySaleType
  auctionDate?: string
  settlementDate?: string
}

export const getPropertySubtitle = ({ settlementDate, saleType, auctionDate }: GetPropertySubtitle) => {
  if (settlementDate && settlementDate !== 'N/A') {
    return `Settlement on ${formatDate(settlementDate)}`
  }
  if (auctionDate && auctionDate !== 'N/A' && saleType?.toLowerCase() === 'auction') {
    return `Auction on ${formatDate(auctionDate)}`
  }
  if (saleType?.toLowerCase() === 'auction') {
    return 'Auction'
  }

  return 'Private Treaty'
}
