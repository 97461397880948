import { GetHomeBuyingJourneyLoan, LoanStatusHome } from '@home-in/models'
import React from 'react'
import { Icon, Icons } from '@elements/icons/icon'
import { InfoIcon } from '@elements/info-icon'
import { Tooltip } from '@elements/tooltip'

export const PreApprovalCard = ({ loanAmount = '', status = '', expiryDate }: GetHomeBuyingJourneyLoan) => {
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  if (status === LoanStatusHome.InProgress) {
    return (
      <div className="flex flex-1">
        <Icon asset={Icons.cbaLogo} size={40} />
        <div className="ml-4">
          <p className="m-0 p-0 text-base font-bold leading-[20px]">Your conditional pre-approval</p>
          <span className="m-0 p-0 text-sm leading-[20px]">In Progress</span>
        </div>
      </div>
    )
  }

  if (!loanAmount) return null
  return (
    <div className="flex flex-1 md:items-center">
      <Icon asset={Icons.cbaLogo} size={40} />
      <div className="ml-4 flex flex-1 flex-col md:flex-row md:items-center md:justify-between">
        <span className="m-0 p-0 text-base font-bold leading-[20px]">Your conditional pre-approval</span>
        <div className="md:text-right">
          <div className="flex md:items-center">
            <p className="my-1 mr-2 p-0 text-[20px] font-bold md:my-0">{formatter.format(Number(loanAmount))}</p>
            <Tooltip
              placement="left"
              label="'Conditional pre-approval' indicates your eligibility for a home loan up to a certain limit. It is not a formal approval but an estimate of how much your lender may be able to lend you, subject to conditions and/or any changes to your financial situation (e.g. expenses and income). This approval expires, check your letter for details. "
            >
              <InfoIcon margin={false} tabIndex={0} />
            </Tooltip>
          </div>
          {expiryDate && <span className="m-0 p-0 text-sm leading-[13px]">Valid until {expiryDate}</span>}
        </div>
      </div>
    </div>
  )
}
